import React, {useEffect, useState} from 'react'
import {matchPath, useLocation} from 'react-router-dom'
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BarChart from '@mui/icons-material/BarChart';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import Addchart from '@mui/icons-material/Addchart';
import Schedule from '@mui/icons-material/Schedule';

const MenuTrainer = () => {
    const { pathname } = useLocation()

    return (
        <React.Fragment>
            <Divider />
            <List>
                {['Academias', 'Availability', 'Schedule', 'Summary'].map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton href={`/trainer/${text.toLocaleLowerCase()}`} selected={!!matchPath(`/trainer/${text.toLocaleLowerCase()}`, pathname)}>
                            <ListItemIcon>
                                {index % 2 === 0 ? <BarChart /> : <Schedule />}
                            </ListItemIcon>
                            <ListItemText primary={text} />
                            {text === 'Summary' && <ErrorOutline sx={{color: '#ff9800'}} />}
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </React.Fragment>
    )
}

export default MenuTrainer

import React, {useEffect, useState, ChangeEvent} from 'react'
import {matchPath, useLocation} from 'react-router-dom'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Delete from '@mui/icons-material/Delete';
import Add from '@mui/icons-material/Add';
import FileCopy from '@mui/icons-material/FileCopy';
import styled from '@emotion/styled';

const Text = styled.div`
    color: #708085;
    font-size: 14px;
    margin: 0 20px;
    line-height: 43px;
    letter-spacing: 0.5px;
`

const Fields = styled.div`
    display: flex;
    align-items: center;
    margin: 0 20px;

    :not(:last-child) {
        margin-bottom: 10px;
    }
`

const Item = styled.div`
    padding: 20px;
    display: flex;
    align-items: center;
    border-top: 1px solid #d4d8da;
`

const chip = {
    marginBottom: '10px',
    marginRight: '10px'
}

const input = {
    width: '80px'
}

interface IComponent {
    name: string,
    available: boolean,
    slots: {id: number, start: string, end: string}[]
}

const AvailabilityItem = (props: IComponent) => {
    const [checked, setChecked] = useState(props.available)
    const [slots, setSlots] = useState([{id: 1, start: '10:00', end: '18:00'}])

    const onChange = (e: ChangeEvent, checked: boolean) => {
        setChecked(checked)
    }

    return (
        <Item>
            <FormControlLabel
                sx={{width: '150px'}}
                control={ <Checkbox checked={checked ? true : false}
                    size="small" onChange={onChange} />
                }
                label={<Typography sx={{ fontSize: 14, letterSpacing: '0.5px' }}>{props.name}</Typography>}
            />
            {checked ?
                <div>
                    {checked && props.slots.length ? props.slots.map((item) => <Fields key={item.id}>
                        <TextField
                            fullWidth
                            defaultValue={item.start}
                            sx={input}
                            inputProps={{sx: {padding: '10px'}}}
                        />
                        <span style={{margin: '0 10px'}}>-</span>
                        <TextField
                            fullWidth
                            defaultValue={item.end}
                            sx={input}
                            inputProps={{sx: {padding: '10px'}}}
                        />
                        <IconButton sx={{marginLeft: '20px'}}>
                            <Delete sx={{fontSize: 20}} />
                        </IconButton>
                    </Fields>
                    ) :
                    slots.map((item) => <Fields key={item.id}>
                        <TextField
                            fullWidth
                            defaultValue={item.start}
                            sx={input}
                            inputProps={{sx: {padding: '10px'}}}
                        />
                        <span style={{margin: '0 10px'}}>-</span>
                        <TextField
                            fullWidth
                            defaultValue={item.end}
                            sx={input}
                            inputProps={{sx: {padding: '10px'}}}
                        />
                        <IconButton sx={{marginLeft: '20px'}}>
                            <Delete sx={{fontSize: 20}} />
                        </IconButton>
                    </Fields>
                    )
                }
                </div> :
                <Text>Unavailable</Text>
            }
            <IconButton sx={{margin: '0 10px 0 auto'}}>
                <Add sx={{fontSize: 20}} />
            </IconButton>
            <IconButton>
                <FileCopy sx={{fontSize: 20}} />
            </IconButton>
        </Item>
    )
}

export default AvailabilityItem

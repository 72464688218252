export default [
    {
        trainer: 'Maria Simoes',
        trainee: 'Juan Carlos',
        academia: '7 Comunique',
        date: '24.07.2022',
        status: 'done',
        value: '50',
        confirmation: true
    },
    {
        trainer: 'Eduardo Cabrita',
        trainee: 'Ana Santos',
        academia: '7 Comunique',
        date: '24.07.2022',
        status: 'done',
        value: '50',
        confirmation: false
    },
    {
        trainer: 'Maria Oliveira',
        trainee: 'Juan Carlos',
        academia: '8 Comunique',
        date: '24.07.2022',
        status: 'last',
        value: '50',
        confirmation: true
    },
    {
        trainer: 'Maria Simoes',
        trainee: 'Juan Carlos',
        academia: '7 Comunique',
        date: '24.07.2022',
        status: 'done',
        value: '50',
        confirmation: true
    },
    {
        trainer: 'Maria Simoes',
        trainee: 'Juan Carlos',
        academia: '7 Comunique',
        date: '24.07.2022',
        status: 'done',
        value: '50',
        confirmation: true
    },
    {
        trainer: 'Eduardo Cabrita',
        trainee: 'Ana Santos',
        academia: '7 Comunique',
        date: '24.07.2022',
        status: 'done',
        value: '50',
        confirmation: false
    },
    {
        trainer: 'Maria Oliveira',
        trainee: 'Juan Carlos',
        academia: '8 Comunique',
        date: '24.07.2022',
        status: 'last',
        value: '50',
        confirmation: true
    },
    {
        trainer: 'Maria Simoes',
        trainee: 'Juan Carlos',
        academia: '7 Comunique',
        date: '24.07.2022',
        status: 'done',
        value: '50',
        confirmation: true
    },
    {
        trainer: 'Maria Simoes',
        trainee: 'Juan Carlos',
        academia: '7 Comunique',
        date: '24.07.2022',
        status: 'done',
        value: '50',
        confirmation: true
    },
    {
        trainer: 'Eduardo Cabrita',
        trainee: 'Ana Santos',
        academia: '7 Comunique',
        date: '24.07.2022',
        status: 'done',
        value: '50',
        confirmation: false
    },
    {
        trainer: 'Maria Oliveira',
        trainee: 'Juan Carlos',
        academia: '8 Comunique',
        date: '24.07.2022',
        status: 'last',
        value: '50',
        confirmation: true
    },
    {
        trainer: 'Maria Simoes',
        trainee: 'Juan Carlos',
        academia: '7 Comunique',
        date: '24.07.2022',
        status: 'done',
        value: '50',
        confirmation: true
    }
]
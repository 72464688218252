import React, {useEffect, useState} from 'react'
import {useNavigate, useParams, useLocation, useOutletContext} from 'react-router-dom'
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BarChart from '@mui/icons-material/BarChart';
import Insights from '@mui/icons-material/Insights';
import Subject from '@mui/icons-material/Subject';
import DashboardCustomize from '@mui/icons-material/DashboardCustomize';
import PersonIcon from '@mui/icons-material/Person';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MailIcon from '@mui/icons-material/Mail';
import Chip from '@mui/material/Chip';
import styled from '@emotion/styled';
import female from 'assets/female.jpg'
import male from 'assets/male.jpeg'

type Types = {
    [key: string]: {
        name: string,
        color: string
    }
}

const types: Types = {
    story: {
        name: "Storytelling",
        color: '#c5cae9'
    },
    image: {
        name: "Image and Success",
        color: '#bbdefb'
    },
    media: {
        name: "Media Training",
        color: '#b2dfdb'
    }
}

const Container = styled.div`
    display: flex;
    border-bottom: 1px solid #d4d8da;
    min-width: 1900px;
    width: 100%;
    align-items: stretch;
`

const Cell = styled.div<{width?: number}>`
    box-sizing: border-box;
    width: ${props => props.width ? props.width : 200}px;
    padding: 10px 20px;
    font-size: 14px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    height: 55px;
    
    :not(:last-child) {
        border-right: 1px solid #d4d8da;
    }
`

const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`

const Photo = styled.div`
    width: 35px;
    height: 35px;
    border-radius: 4px;
    overflow: hidden;
`

const Link = styled.a`
    cursor: pointer;
    letter-spacing: 0.5px;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.87);
    border-bottom: 1px solid;
    padding-bottom: 2.5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

interface IComponent {
    name: string,
    phone: string,
    email: string,
    photo: string,
    presentation: string,
    final: string,
    company: string,
    role: string
}

const TraineesItem = (props: IComponent) => {
    return (
        <Container>
            <Cell width={300}>{props.name}</Cell>
            <Cell>{props.photo ? <Photo><Img src={male} /></Photo> : <Photo><Img src={female} /></Photo>}</Cell>
            <Cell>{props.phone}</Cell>
            <Cell>{props.email}</Cell>
            <Cell width={300}><Link>{props.presentation}</Link></Cell>
            <Cell width={300}><Link>{props.final}</Link></Cell>
            <Cell>{props.company}</Cell>
            <Cell>{props.role}</Cell>
        </Container>
    )
}

export default TraineesItem

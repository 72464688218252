import {
    createBrowserRouter,
    createRoutesFromElements,
    RouterProvider,
    Route,
    Navigate,
    Outlet
} from 'react-router-dom'
import {ThemeProvider} from '@mui/material/styles'
import Projects from 'containers/Projects'
import Project from 'containers/Project'
import Root from 'containers/Root'
import Sessions from 'containers/Sessions'
import Trainees from 'containers/Trainees'
import Schedule from 'containers/Schedule'
import History from 'containers/History'
import AcademiaHistory from 'containers/AcademiaHistory'
import AcademiaTasks from 'containers/AcademiaTasks'
import Forecast from 'containers/Forecast'
import Proposal from 'containers/Proposal'
import Academias from 'containers/Academias'
import AcademiaSessions from 'containers/AcademiaSessions'
import AcademiaAreas from 'containers/AcademiaAreas'
import AcademiaTrainees from 'containers/AcademiaTrainees'
import Trainee from 'containers/Trainee'
import TraineeSchedule from 'containers/TraineeSchedule'
import AcademiaSchedule from 'containers/AcademiaSchedule'
import Login from 'containers/Login'
import Trainer from 'containers/Trainer'
import TrainerSummary from 'containers/TrainerSummary'
import TrainerAvailability from 'containers/TrainerAvailability'
import TrainerSessions from 'containers/TrainerSessions'
import TrainerTrainees from 'containers/TrainerTrainees'
import TrainerSchedule from 'containers/TrainerSchedule'
import ProjectTrainee from 'containers/ProjectTrainee'
import Commissions from 'containers/Commissions'
import ProjectInvoices from 'containers/ProjectInvoices'
import AcademiaInvoices from 'containers/AcademiaInvoices'
import Trainers from 'containers/Trainers'
import theme from 'theme'

const Router = () => {
    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route element={<Outlet />}>
                <Route path='/login' element={<Login />} />
                <Route element={<Root />}>
                    <Route path='/projects' element={<Projects />} />
                    <Route path='/tasks' element={<Project />} />
                    <Route path='/sessions' element={<Sessions />} />
                    <Route path='/trainees' element={<Trainees />} />
                    <Route path='/schedule' element={<Schedule />} />
                    <Route path='/history' element={<History />} />
                    <Route path='/forecast' element={<Forecast />} />
                    <Route path='/proposal' element={<Proposal />} />
                    <Route path='/academias' element={<Academias />} />

                    <Route path='/trainee' element={<Trainee />} />
                    <Route path='/trainee/schedule' element={<TraineeSchedule />} />

                    <Route path='/trainee/project' element={<ProjectTrainee />} />

                    <Route path='/trainer/academias' element={<Trainer />} />
                    <Route path='/trainer/summary' element={<TrainerSummary />} />
                    <Route path='/trainer/availability' element={<TrainerAvailability />} />
                    <Route path='/trainer/sessions' element={<TrainerSessions />} />
                    <Route path='/trainer/trainees' element={<TrainerTrainees />} />
                    <Route path='/trainer/schedule' element={<TrainerSchedule />} />

                    <Route path='/academia/tasks' element={<AcademiaTasks />} />
                    <Route path='/academia/history' element={<AcademiaHistory />} />
                    <Route path='/academia/sessions' element={<AcademiaSessions />} />
                    <Route path='/academia/areas' element={<AcademiaAreas />} />
                    <Route path='/academia/trainees' element={<AcademiaTrainees />} />
                    <Route path='/academia/schedule' element={<AcademiaSchedule />} />

                    <Route path='/manager/commissions' element={<Commissions />} />
                    <Route path='/manager/projects' element={<ProjectInvoices />} />
                    <Route path='/manager/academias' element={<AcademiaInvoices />} />
                    <Route path='/manager/trainers' element={<Trainers />} />
                    <Route path='*' element={<Navigate to={'/projects'} replace={true} />} />
                </Route>
            </Route>
        )
    )

    return <RouterProvider router={router} />
}

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <Router />
        </ThemeProvider>
    )
}

export default App

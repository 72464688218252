import React, {useEffect} from 'react'
import {Outlet, useNavigate} from 'react-router-dom'
import AppBar from '@mui/material/AppBar';
import Tooltip from '@mui/material/Tooltip';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Logout from '@mui/icons-material/Logout';

const Root = () => {
    const navigate = useNavigate()

    const onClick = () => {
        navigate('/login')
    }

    return (
        <React.Fragment>
            <AppBar position="fixed" sx={{ zIndex: 1300 }}>
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    
                    </Typography>
                    <div>
                    <Tooltip title="Logout" placement="bottom">
                        <IconButton
                            size="large"
                            color='info'
                            onClick={onClick}
                        >
                            <Logout />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Maria Oliveira" placement="bottom">
                        <IconButton
                            size="large"
                            color='info'
                        >
                            <AccountCircle />
                        </IconButton>
                    </Tooltip>
                    </div>
                </Toolbar>
            </AppBar>
            <Outlet />
        </React.Fragment>
    )
}

export default Root

import React, {useEffect, useState} from 'react'
import {useNavigate, useParams, useLocation, useOutletContext} from 'react-router-dom'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ThumbUpAlt from '@mui/icons-material/ThumbUpAlt';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import styled from '@emotion/styled'

type Statuses = {
    [key: string]: {
        name: string,
        color: string
    }
}

const statuses: Statuses = {
    done: {
        name: "Done",
        color: '#c8e6c9'
    },
    last: {
        name: "Last minute cancelation",
        color: '#ffecb3'
    }
}

const Container = styled.div`
    display: flex;
    border-bottom: 1px solid #d4d8da;
    min-width: 1500px;
    width: 100%;
    align-items: stretch;
    height: 53px;
`

const Cell = styled.div<{width?: number}>`
    box-sizing: border-box;
    width: ${props => props.width ? props.width : 200}px;
    padding: 10px 20px;
    font-size: 14px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    
    :not(:last-child) {
        border-right: 1px solid #d4d8da;
    }
`

const chip = {
    marginBottom: '10px',
    marginRight: '10px'
}

const input = {
    marginBottom: '10px',
    marginTop: '10px'
}

interface IComponent {
    trainer: string,
    trainee: string,
    academia: string,
    date: string,
    status: string,
    value: string,
    confirmation: boolean
}

const Item = (props: IComponent) => {
    return (
        <Container>
            <Cell>{props.trainer}</Cell>
            <Cell>{props.trainee}</Cell>
            <Cell>{props.academia}</Cell>
            <Cell>{props.date}</Cell>
            <Cell width={300}><Chip label={statuses[props.status].name} sx={{background: statuses[props.status].color}} /></Cell>
            <Cell>€ {props.value}</Cell>
            <Cell>
                {props.confirmation &&
                    <ThumbUpAlt sx={{fontSize: 25, margin: 'auto', color: '#4caf50'}} />
                }
            </Cell>
        </Container>
    )
}

export default Item

import React, {useEffect, useState} from 'react'
import {useNavigate, useParams, useLocation, useOutletContext} from 'react-router-dom'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Autocomplete from '@mui/material/Autocomplete';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BarChart from '@mui/icons-material/BarChart';
import Insights from '@mui/icons-material/Insights';
import Subject from '@mui/icons-material/Subject';
import DashboardCustomize from '@mui/icons-material/DashboardCustomize';
import PersonIcon from '@mui/icons-material/Person';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MailIcon from '@mui/icons-material/Mail';
import Chip from '@mui/material/Chip';
import styled from '@emotion/styled';

type Statuses = {
    [key: string]: {
        name: string,
        color: string
    }
}

const statuses: Statuses = {
    delayed: {
        name: "Delayed",
        color: '#fff9c4'
    },
    rejected: {
        name: "Rejected",
        color: '#ffcdd2'
    },
    complete: {
        name: "Complete",
        color: '#c8e6c9'
    }
}

const Container = styled.div`
    display: flex;
    border-bottom: 1px solid #d4d8da;
    min-width: 1400px;
    width: 100%;
    align-items: stretch;
`

const Cell = styled.div<{width?: number}>`
    box-sizing: border-box;
    width: ${props => props.width ? props.width : 200}px;
    padding: 10px 20px;
    font-size: 14px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    
    :not(:last-child) {
        border-right: 1px solid #d4d8da;
    }
`

const Chips = styled.div`
    display: flex;
    align-items: center;
`

const Field = styled.span`
    margin: 0 5px;
    font-weight: 500;
    color: #c30084;
`

interface IComponent {
    name: string,
    date: string,
    task: string,
    change: {
        [key: string]: string
    }
}

const HistoryItem = (props: IComponent) => {
    return (
        <Container>
            <Cell width={300}>{props.task}</Cell>
            <Cell width={600} style={{justifyContent: 'flex-start'}}>
            {props.change.field === 'Status' ?
                <React.Fragment>
                    {props.name} has changed <Field>{props.change.field}</Field> from
                    <Chips>
                        <Chip label={statuses[props.change.from].name} sx={{background: statuses[props.change.from].color, margin: '0 5px'}} />
                        {' to '}
                        <Chip label={statuses[props.change.to].name} sx={{background: statuses[props.change.to].color, margin: '0 5px'}} />
                    </Chips>
                </React.Fragment> :
                <React.Fragment>
                {props.name} has changed <Field>{props.change.field}</Field> from
                    <Chips>
                        <Chip label={props.change.from} sx={{margin: '0 5px'}} />
                        {' to '}
                        <Chip label={props.change.to} sx={{margin: '0 5px'}} />
                    </Chips>
            </React.Fragment>
            }
            </Cell>
            <Cell>{props.date} <CalendarMonthIcon sx={{marginLeft: '10px', fontSize: 20, color: '#708085'}} /></Cell>
            <Cell width={300}>{props.name}</Cell>
        </Container>
    )
}

export default HistoryItem

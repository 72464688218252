export default [
    {
        trainer: 'Maria Simoes',
        date: '24.07.2022',
        area: 'media',
        trainee: 'Juan Carlos',
        status: 'done',
        academia: '7 Comunique'
    },
    {
        trainer: 'Eduardo Cabrita',
        date: '24.07.2022',
        area: 'media',
        trainee: 'Ana Almeida',
        status: 'last',
        academia: '7 Comunique'
    },
    {
        trainer: 'Maria Oliveira',
        date: '24.07.2022',
        area: 'story',
        trainee: 'Ana Patricia Santos',
        status: 'done',
        academia: '7 Comunique'
    },
    {
        trainer: 'Eduardo Cabrita',
        date: '24.07.2022',
        area: 'media',
        trainee: 'Ana Almeida',
        status: 'done',
        academia: '8 Comunique'
    },
    {
        trainer: 'Maria Simoes',
        date: '24.07.2022',
        area: 'image',
        trainee: 'Juan Carlos',
        status: 'done',
        academia: '7 Comunique'
    },
    {
        trainer: 'Maria Oliveira',
        date: '24.07.2022',
        area: 'story',
        trainee: 'Ana Patricia Santos',
        status: 'last',
        academia: '10 Comunique'
    },
    {
        trainer: 'Eduardo Cabrita',
        date: '24.07.2022',
        area: 'media',
        trainee: 'Ana Almeida',
        status: 'done',
        academia: '7 Comunique'
    },
    {
        trainer: 'Maria Simoes',
        date: '24.07.2022',
        area: 'media',
        trainee: 'Juan Carlos',
        status: 'done',
        academia: '7 Comunique'
    },
    {
        trainer: 'Maria Oliveira',
        date: '24.07.2022',
        area: 'story',
        trainee: 'Ana Patricia Santos',
        status: 'done',
        academia: '7 Comunique'
    },
    {
        trainer: 'Eduardo Cabrita',
        date: '24.07.2022',
        area: 'media',
        trainee: 'Ana Almeida',
        status: 'done',
        academia: '7 Comunique'
    },
    {
        trainer: 'Maria Simoes',
        date: '24.07.2022',
        area: 'image',
        trainee: 'Juan Carlos',
        status: 'last',
        academia: '10 Comunique'
    },
    {
        trainer: 'Maria Oliveira',
        date: '24.07.2022',
        area: 'story',
        trainee: 'Ana Patricia Santos',
        status: 'done',
        academia: '7 Comunique'
    },
    {
        trainer: 'Eduardo Cabrita',
        date: '24.07.2022',
        area: 'media',
        trainee: 'Ana Almeida',
        status: 'last',
        academia: '7 Comunique'
    },
    {
        trainer: 'Maria Simoes',
        date: '24.07.2022',
        area: 'media',
        trainee: 'Juan Carlos',
        status: 'done',
        academia: '7 Comunique'
    }
]
export default [
    {
        trainer: 'Eduardo Cabrita',
        area: 'media',
        status: 'invited'
    },
    {
        trainer: 'Maria Simoes',
        area: 'media',
        status: 'accepted'
    },
    {
        trainer: 'Maria Oliveira',
        area: 'story',
        status: 'refused'
    },
    {
        trainer: 'Eduardo Cabrita',
        area: 'media',
        status: 'invited'
    },
    {
        trainer: 'Maria Simoes',
        area: 'media',
        status: 'accepted'
    },
    {
        trainer: 'Maria Oliveira',
        area: 'story',
        status: 'refused'
    }
]
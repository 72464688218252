type Statuses = {
    [key: string]: {
        value: string,
        color: string
    }
}

const statuses: Statuses = {
    complete: {value: 'Complete', color: 'success'},
    delayed: {value: 'Delayed', color: 'error'},
    planning: {value: 'Planning', color: 'secondary'}
}

export default statuses
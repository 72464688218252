export default [
    {
        name: 'João Pereira',
        date: '24.07.2022 10.00',
        task: 'Find an event location',
        change: {
            field: 'Status',
            from: 'delayed',
            to: 'rejected'
        }
    },
    {
        name: 'Rui Pedro Ribeiro Correia',
        date: '24.07.2022 10.00',
        task: 'Find an event location',
        change: {
            field: 'Status',
            from: 'delayed',
            to: 'complete'
        }
    },
    {
        name: 'Nuno Rui Oliveira Santos',
        date: '24.07.2022 10.00',
        task: 'Find an event location',
        change: {
            field: 'Status',
            from: 'rejected',
            to: 'complete'
        }
    },
    {
        name: 'Maria Simoes',
        date: '24.07.2022 10.00',
        task: 'Prepare stuff for the event',
        change: {
            field: 'Assigned to',
            from: 'Maria Oliveira',
            to: 'Augusto Ferreira'
        }
    }   ,
    {
        name: 'Maria Lopes Ribeiro',
        date: '24.07.2022 10.00',
        task: 'Find an event location',
        change: {
            field: 'Status',
            from: 'delayed',
            to: 'complete'
        }
    },
    {
        name: 'Augusto Ferreira',
        date: '24.07.2022 10.00',
        task: 'Find an event location',
        change: {
            field: 'Status',
            from: 'delayed',
            to: 'complete'
        }
    },
    {
        name: 'Jose Manuel Santos Pereira',
        date: '24.07.2022 10.00',
        task: 'Find an event location',
        change: {
            field: 'Status',
            from: 'delayed',
            to: 'rejected'
        }
    },
    {
        name: 'Maria Simoes',
        date: '24.07.2022 10.00',
        task: 'Prepare stuff for the event',
        change: {
            field: 'Assigned to',
            from: 'Maria Oliveira',
            to: 'Augusto Ferreira'
        }
    },
    {
        name: 'Maria Oliveira',
        date: '24.07.2022 10.00',
        task: 'Find an event location',
        change: {
            field: 'Status',
            from: 'delayed',
            to: 'rejected'
        }
    },
    {
        name: 'Augusto Ferreira',
        date: '24.07.2022 10.00',
        task: 'Find an event location',
        change: {
            field: 'Status',
            from: 'delayed',
            to: 'rejected'
        }
    },
    {
        name: 'Maria Oliveira',
        date: '24.07.2022 10.00',
        task: 'Find an event location',
        change: {
            field: 'Status',
            from: 'delayed',
            to: 'rejected'
        }
    },
    {
        name: 'Augusto Ferreira',
        date: '24.07.2022 10.00',
        task: 'Find an event location',
        change: {
            field: 'Status',
            from: 'delayed',
            to: 'rejected'
        }
    },
]
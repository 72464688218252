export default [
    {
        title: 'Eduardo Cabrita',
        date: '24.07.2022',
        type: 'media',
        locale: 'remote',
        address: '',
        status: 'invited'
    },
    {
        title: 'Eduardo Cabrita',
        date: '24.07.2022',
        type: 'image',
        locale: 'presential',
        address: 'Rua de Borges Carneiro 61B, 1200-617 Lisboa',
        status: 'accepted'
    },
    {
        title: 'Eduardo Cabrita',
        date: '24.07.2022',
        type: 'media',
        locale: 'remote',
        address: '',
        status: 'refused'
    },
    {
        title: 'Maria Simoes',
        date: '24.07.2022',
        type: 'story',
        locale: 'remote',
        address: '',
        status: 'invited'
}   ,
    {
        title: 'Eduardo Cabrita',
        date: '24.07.2022',
        type: 'story',
        locale: 'presential',
        address: 'Rua de Borges Carneiro 61B, 1200-617 Lisboa',
        status: 'invited'
    },
    {
        title: '',
        date: '',
        type: 'media',
        locale: 'remote',
        address: '',
        status: 'invited'
    },
    {
        title: 'Eduardo Cabrita',
        date: '24.07.2022',
        type: 'story',
        locale: 'remote',
        address: '',
        status: 'invited'
    },
    {
        title: 'Maria Oliveira',
        date: '24.07.2022',
        type: 'story',
        locale: 'presential',
        address: 'Rua de Borges Carneiro 61B, 1200-617 Lisboa',
        status: 'invited'
    },
    {
        title: 'Maria Oliveira',
        date: '24.07.2022',
        type: 'story',
        locale: 'remote',
        address: '',
        status: 'invited'
    },
    {
        title: 'Augusto Ferreira',
        date: '24.07.2022',
        type: 'story',
        locale: 'remote',
        address: '',
        status: 'invited'
    },
    {
        title: 'Maria Oliveira',
        date: '24.07.2022',
        type: 'story',
        locale: 'remote',
        address: '',
        status: 'invited'
    },
    {
        title: 'Augusto Ferreira',
        date: '24.07.2022',
        type: 'story',
        locale: 'remote',
        address: '',
        status: 'invited'
    },
]
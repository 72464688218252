import React, {useEffect, useState} from 'react'
import {useNavigate, matchPath, useLocation, useOutletContext} from 'react-router-dom'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Euro from '@mui/icons-material/Euro';
import Add from '@mui/icons-material/Add';
import TaskAlt from '@mui/icons-material/TaskAlt';
import AutoFixHigh from '@mui/icons-material/AutoFixHigh';
import PersonIcon from '@mui/icons-material/Person';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MailIcon from '@mui/icons-material/Mail';
import Chip from '@mui/material/Chip';
import NavigateNext from '@mui/icons-material/NavigateNext';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField'
import NavigateBefore from '@mui/icons-material/NavigateBefore';
import BarChart from '@mui/icons-material/BarChart';
import EditIcon from '@mui/icons-material/Edit';
import Insights from '@mui/icons-material/Insights';
import styled from '@emotion/styled';
import ListMenu from 'components/Menu'

const Menu = styled.div`
    margin-bottom: 20px;
    align-items: center;
    display: flex;
    justify-content: flex-end;
`

const Title = styled.div`
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    margin-bottom: 10px;
    color: #708085;
`

const Item = styled.div`
    font-size: 13px;

    :not(:last-child) {
        margin-bottom: 20px;
    }
`

const SumItem = styled.div`
:not(:last-child) {
    margin-bottom: 5px;
}
`

const Top = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    letter-spacing: 0.5px;
`

const Sum = styled.div`
    font-size: 13px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`

const Column = styled.div`
    width: calc(25% - 15px);
`

const Project = styled.div`
    margin-bottom: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

const Stepper = styled.div`
    background: #a5d6a7;
    height: 5px;
    border-radius: 5px;
    overflow: hidden;
`

const Steps = styled.div<{width: number}>`
    background: #388e3c;
    width: ${props => props.width}%;
    height: 100%;
`

const chip = {
    marginBottom: '10px',
    marginRight: '10px'
}

const item = {
    padding: '20px',
    marginBottom: '20px'
}

const paper = {
    padding: '20px',
    marginBottom: '20px',
    backgroundColor: '#f0f2f2'
}

const Forecast = () => {
    const { pathname } = useLocation()

    return (
        <React.Fragment>
            <Drawer
                sx={{
                    width: 250
                }}
                PaperProps={{
                    sx: {
                        width: 250
                    }
                }}
                variant="permanent"
                anchor="left"
            >
            <Toolbar />
            <ListMenu />
            </Drawer>
            <Box component="main" sx={{p: 3, marginTop: '64px', marginLeft: '250px', minWidth: '900px'}}>
                <Menu>
                <IconButton size="small">
                    <NavigateBefore />
                </IconButton>
                <Chip label="Today" sx={{cursor: 'pointer', letterSpacing: '0.5px', margin: '0 5px'}} />
                <IconButton size="small">
                    <NavigateNext />
                </IconButton>
                </Menu>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Column>
                        <Paper sx={paper}>
                            <Top>
                            <Chip label="March" sx={{background: '#80deea'}} />
                            <Sum>
                                <SumItem>€ 40 000</SumItem>
                                <SumItem style={{color: '#757575'}}>€ 20 000</SumItem>
                                <SumItem style={{color: '#43a047'}}>€ 20 000</SumItem>
                            </Sum>
                            </Top>
                        </Paper>
                        <Paper sx={item}>
                            <Project>FMIM Corporate Academy</Project>
                                <Item>
                                    <Title>
                                        <PersonIcon sx={{marginRight: '5px', fontSize: 18}} /> Client
                                    </Title>
                                    <div>Tecnimede</div>
                                </Item>
                                <Item>
                                    <Title>
                                        <CalendarMonthIcon sx={{marginRight: '5px', fontSize: 18}} /> Expected close date
                                    </Title>
                                    <div>23.10.2023</div>
                                </Item>
                                <Item>
                                    <Title>
                                        <Euro sx={{marginRight: '5px', fontSize: 18}} /> Budget
                                    </Title>
                                    <div>€ 20 000</div>
                                </Item>
                                <Item>
                                    <Stepper><Steps width={30}></Steps></Stepper>
                                </Item>
                        </Paper>
                        <Paper sx={{...item, background: '#e8f5e9'}}>
                            <Project>FMIM Corporate Academy</Project>
                                <Item>
                                    <Title>
                                        <PersonIcon sx={{marginRight: '5px', fontSize: 18}} /> Client
                                    </Title>
                                    <div>Tecnimede</div>
                                </Item>
                                <Item>
                                    <Title>
                                        <CalendarMonthIcon sx={{marginRight: '5px', fontSize: 18}} /> Expected close date
                                    </Title>
                                    <div>23.10.2023</div>
                                </Item>
                                <Item>
                                    <Title>
                                        <Euro sx={{marginRight: '5px', fontSize: 18}} /> Budget
                                    </Title>
                                    <div>€ 20 000</div>
                                </Item>
                                <Item>
                                    <Stepper><Steps width={100}></Steps></Stepper>
                                </Item>
                        </Paper>
                    </Column>

                    <Column>
                        <Paper sx={paper}>
                            <Top>
                            <Chip label="April" sx={{background: '#9fa8da'}} />
                            <Sum>
                                <SumItem>€ 20 000</SumItem>
                                <SumItem style={{color: '#757575'}}>€ 0</SumItem>
                                <SumItem style={{color: '#43a047'}}>€ 20 000</SumItem>
                            </Sum>
                            </Top>
                        </Paper>
                        <Paper sx={{...item, background: '#e8f5e9'}}>
                            <Project>FMIM Corporate Academy</Project>
                            <Item>
                                <Title>
                                    <PersonIcon sx={{marginRight: '5px', fontSize: 18}} /> Client
                                </Title>
                                <div>Tecnimede</div>
                            </Item>
                            <Item>
                                <Title>
                                    <CalendarMonthIcon sx={{marginRight: '5px', fontSize: 18}} /> Expected close date
                                </Title>
                                <div>23.10.2023</div>
                            </Item>
                            <Item>
                                <Title>
                                    <Euro sx={{marginRight: '5px', fontSize: 18}} /> Budget
                                </Title>
                                <div>€ 20 000</div>
                            </Item>
                            <Item>
                                <Stepper><Steps width={100}></Steps></Stepper>
                            </Item>
                        </Paper>
                    </Column>

                    <Column>
                        <Paper sx={paper}>
                            <Top>
                            <Chip label="May" sx={{background: '#f48fb1'}} />
                            <Sum>
                                <SumItem>€ 50 000</SumItem>
                                <SumItem style={{color: '#757575'}}>€ 40 000</SumItem>
                                <SumItem style={{color: '#43a047'}}>€ 10 000</SumItem>
                            </Sum>
                            </Top>
                        </Paper>
                        <Paper sx={item}>
                            <Project>FMIM Corporate Academy</Project>
                                <Item>
                                    <Title>
                                        <PersonIcon sx={{marginRight: '5px', fontSize: 18}} /> Client
                                    </Title>
                                    <div>Tecnimede</div>
                                </Item>
                                <Item>
                                    <Title>
                                        <CalendarMonthIcon sx={{marginRight: '5px', fontSize: 18}} /> Expected close date
                                    </Title>
                                    <div>23.10.2023</div>
                                </Item>
                                <Item>
                                    <Title>
                                        <Euro sx={{marginRight: '5px', fontSize: 18}} /> Budget
                                    </Title>
                                    <div>€ 20 000</div>
                                </Item>
                                <Item>
                                    <Stepper><Steps width={30}></Steps></Stepper>
                                </Item>
                        </Paper>
                    </Column>

                    <Column>
                        <Paper sx={paper}>
                            <Top>
                            <Chip label="June" sx={{background: '#90caf9'}} />
                            <Sum>
                                <SumItem>€ 50 000</SumItem>
                                <SumItem style={{color: '#757575'}}>€ 40 000</SumItem>
                                <SumItem style={{color: '#43a047'}}>€ 10 000</SumItem>
                            </Sum>
                            </Top>
                        </Paper>
                        <Paper sx={item}>
                                <Project>FMIM Corporate Academy</Project>
                                    <Item>
                                        <Title>
                                            <PersonIcon sx={{marginRight: '5px', fontSize: 18}} /> Client
                                        </Title>
                                        <div>Tecnimede</div>
                                    </Item>
                                    <Item>
                                        <Title>
                                            <CalendarMonthIcon sx={{marginRight: '5px', fontSize: 18}} /> Expected close date
                                        </Title>
                                        <div>23.10.2023</div>
                                    </Item>
                                    <Item>
                                        <Title>
                                            <Euro sx={{marginRight: '5px', fontSize: 18}} /> Budget
                                        </Title>
                                        <div>€ 20 000</div>
                                    </Item>
                                    <Item>
                                        <Stepper><Steps width={50}></Steps></Stepper>
                                    </Item>
                        </Paper>
                    </Column>
                </div>
                
            </Box>
        </React.Fragment>
    )
}

export default Forecast

import React, {useEffect, useState} from 'react'
import {useNavigate, useParams, useLocation, matchPath} from 'react-router-dom'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import Chip from '@mui/material/Chip';
import Toolbar from '@mui/material/Toolbar';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import styled from '@emotion/styled';
import MenuTrainer from 'components/MenuTrainer'
import TrainerSummaryItem from 'components/TrainerSummaryItem'
import Breadcrumbs from 'components/Breadcrumbs'
import trainerEvents from 'enums/trainerEvents'

const Container = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;

    .fc .fc-toolbar.fc-header-toolbar {
        padding: 10px 0;
        margin-bottom: 0;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        clip-path: inset(0px 0px -5px 0px);
    }

    .fc .fc-toolbar-title {
        font-size: 20px;
        font-weight: 500;
        color: #4a4a4a;
        letter-spacing: 0.5px;
    }

    .fc .fc-col-header-cell-cushion {
        padding: 5px;
        letter-spacing: 0.5px;
        color: #708085;
        font-weight: 500;
        font-size: 12px;
    }

    .fc-daygrid-block-event .fc-event-time {
        font-weight: 500;
    }

    .fc-theme-standard .fc-scrollgrid {
        border: none;
    }

    .fc .fc-button {
        border: none;
        text-transform: uppercase;
        letter-spacing: 1px;
	    font-size: 12px;
        padding: 5px 15px;
        border-radius: 0;
    }

    .fc .fc-button-primary {
        background-color: transparent;
        color: #4a4a4a;
    }

    .fc .fc-button-primary:focus,
    .fc .fc-button-primary:not(:disabled).fc-button-active:focus,
    .fc .fc-button-primary:not(:disabled):active:focus {
        box-shadow: none;
    }

    .fc .fc-button-primary:not(:disabled).fc-button-active,
    .fc .fc-button-primary:not(:disabled):active {
        background-color: transparent;
        color: #4a4a4a;
    }

    .fc .fc-daygrid-more-link {
        width: 100%;
    }

    .fc .fc-daygrid-more-link:hover {
        background-color: transparent;
    }

    .fc .fc-daygrid-day-frame {
        min-height: 90px;
    }

    .fc-h-event .fc-event-title {
        text-overflow: ellipsis;
    }

    .fc-h-event .fc-event-time {
        overflow: visible;
    }

    .fc .fc-col-header-cell-cushion {
        padding: 5px;
    }

    fc .fc-daygrid-day-number {
        padding: 2px 4px;
    }

    .fc .fc-event {
        cursor: pointer;
    }

    .fc-theme-standard .fc-list {
        border: none;
    }
`

const months = [
    {
        id: 1,
        name: 'April'
    },
    {
        id: 2,
        name: 'May'
    },
    {
        id: 3,
        name: 'June'
    }
]

const TrainerSchedule = () => {
    const [filter, setFilter] = useState<string>('May')
    const [openFilter, setOpenFilter] = useState<HTMLDivElement>()

    const navigate = useNavigate()
    const { pathname } = useLocation()

    const onChipClick = (e: React.MouseEvent<HTMLDivElement>) => {
        setOpenFilter(e.currentTarget)
    }

    const handleClose = () => {
        setOpenFilter(undefined)
    }

    const onMenuItemClick = (value: string) => {
        if (value === filter) {
            setFilter('')
        } else {
            setFilter(value)
        }
        
        handleClose()
    }

    return (
        <React.Fragment>
            <Drawer
                sx={{
                    width: 250
                }}
                PaperProps={{
                    sx: {
                        width: 250
                    }
                }}
                variant="permanent"
                anchor="left"
            >
            <Toolbar />
            <MenuTrainer />
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, marginTop: '64px', marginLeft: '250px', height: 'calc(100vh - 64px)'}}>
                <Container>
                    <FullCalendar
                        headerToolbar ={{
                            center: 'title',
                            end: '',
                            start: 'prev next today'
                        }}
                        views={{
                            dayGridMonth: {
                                dayMaxEventRows: 4
                            }
                        }}
                        initialView="dayGridMonth"
                        firstDay={1}
                        plugins={[ dayGridPlugin ]}
                        eventDisplay='block'
                        height='100%'
                        dayMaxEventRows={true}
                        expandRows={true}
                        events={trainerEvents}
                        eventTimeFormat={{
                            hour: '2-digit',
                            minute: '2-digit',
                            meridiem: false,
                            hour12: false
                        }}
                    />
                </Container>
            </Box>
            <Menu
                anchorEl={openFilter}
                open={!!openFilter}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{sx: {
                    marginTop: '10px'
                }}}
            >
                {months.map(item => (
                    <MenuItem onClick={() => onMenuItemClick(item.name)} sx={{width: '100px'}}
                        key={item.name}
                        value={item.name}
                    >
                        {item.name}
                    </MenuItem>
                ))}
            </Menu>
        </React.Fragment>
    )
}

export default TrainerSchedule

import React, {useEffect, useState} from 'react'
import {useNavigate, useParams, useLocation, useOutletContext} from 'react-router-dom'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Autocomplete from '@mui/material/Autocomplete';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BarChart from '@mui/icons-material/BarChart';
import Insights from '@mui/icons-material/Insights';
import Subject from '@mui/icons-material/Subject';
import DashboardCustomize from '@mui/icons-material/DashboardCustomize';
import PersonIcon from '@mui/icons-material/Person';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MailIcon from '@mui/icons-material/Mail';
import Chip from '@mui/material/Chip';
import styled from '@emotion/styled';
import contacts from 'enums/contacts'
import statuses from 'enums/statuses'

const Container = styled.div`
    display: flex;
    border-bottom: 1px solid #d4d8da;
    min-width: 900px;
    width: 100%;
    align-items: stretch;
    height: 45px;
`

const Cell = styled.div<{width?: number}>`
    box-sizing: border-box;
    width: ${props => props.width ? props.width : 200}px;
    padding: 10px 20px;
    font-size: 14px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    
    :not(:last-child) {
        border-right: 1px solid #d4d8da;
    }
`

const Input = styled.div<{width?: number}>`
    box-sizing: border-box;
    width: ${props => props.width ? props.width : 200}px;
    padding: 10px 20px;
    font-size: 14px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    position: relative;
    line-height: 40px;
    
    :not(:last-child) {
        border-right: 1px solid #d4d8da;
    }

    :focus {
        outline: 0;

        :after {
            outline: #0084c3 solid 2px;
            border-radius: 2px;
            z-index: 4;
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            cursor: text;
        }
    }
`

const chip = {
    marginBottom: '10px',
    marginRight: '10px'
}

const input = {
    marginBottom: '10px',
    marginTop: '10px'
}

interface IComponent {
    name: string,
    price: string,
    units: string,
    total: string
}

const ProposalItem = (props: IComponent) => {
    return (
        <Container>
            <Cell width={300}>{props.name}</Cell>
            <Input contentEditable suppressContentEditableWarning>{props.price}</Input>
            <Input contentEditable suppressContentEditableWarning>{props.units}</Input>
            <Cell>{props.total}</Cell>
        </Container>
    )
}

export default ProposalItem

export default [
    {
        title: 'Eduardo Cabrita',
        price: '200',
        hours: '8',
        total: '1600'
    },
    {
        title: 'Maria Simoes',
        price: '100',
        hours: '5',
        total: '500'
    },
    {
        title: 'Maria Oliveira',
        price: '100',
        hours: '5',
        total: '500'
    },
    {
        title: 'Augusto Ferreira',
        price: '100',
        hours: '5',
        total: '500'
    }
]
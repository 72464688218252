import React, {useEffect, useState} from 'react'
import {useNavigate, useParams, useLocation, matchPath} from 'react-router-dom'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ChecklistIcon from '@mui/icons-material/Checklist';
import Schedule from '@mui/icons-material/Schedule';
import styled from '@emotion/styled';
import ListMenu from 'components/Menu'
import HistoryItem from 'components/HistoryItem'
import Breadcrumbs from 'components/Breadcrumbs'
import history from 'enums/history'
import statuses from 'enums/statuses'

const Title = styled.div`
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    margin-bottom: 10px;
    color: #708085;
`

const Item = styled.div`
    font-size: 13px;
`

const Info = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
`

const Top = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.5px;
    position: relative;
`

const Table = styled.div`
    overflow: auto;
`

const Header = styled.div`
    display: flex;
    top: 0px;
    position: sticky;
    z-index: 3;
    background-color: #f0f2f2;
    letter-spacing: 0.5px;
    color: #708085;
    font-size: 13px;
    border-bottom: 1px solid #d4d8da;
    background-color: #f0f2f2;
    min-width: 1400px;
    width: 100%;
`

const Cell = styled.div<{width?: number}>`
    box-sizing: border-box;
    width: ${props => props.width ? props.width : 200}px;
    padding: 20px;
    font-size: 14px;
    
    :not(:last-child) {
        border-right: 1px solid #d4d8da;
    }
`

const History = () => {
    const { pathname } = useLocation()

    return (
        <React.Fragment>
            <Drawer
                sx={{
                    width: 250
                }}
                PaperProps={{
                    sx: {
                        width: 250
                    }
                }}
                variant="permanent"
                anchor="left"
            >
            <Toolbar />
            <Divider />
            <List>
                {['Tasks', 'History', 'Sessions', 'Schedule', 'Trainees', 'Proposal'].map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton disableRipple href={`/${text.toLocaleLowerCase()}`} selected={!!matchPath(text.toLocaleLowerCase(), pathname)}>
                            <ListItemIcon>
                                {index % 2 === 0 ? <ChecklistIcon /> : <Schedule />}
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <ListMenu />
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: '64px', marginLeft: '250px'}}>
                <Breadcrumbs />
                <Paper>
                    <Table>
                        <Header>
                            <Cell width={300}>Task</Cell>
                            <Cell width={600}>Change</Cell>
                            <Cell>Date</Cell>
                            <Cell width={300}>Name</Cell>
                        </Header>
                        {history.map((item, index) => (
                            <HistoryItem key={index} name={item.name} date={item.date} task={item.task} change={item.change}/>
                        ))}
                    </Table>
                </Paper>
            </Box>
        </React.Fragment>
    )
}

export default History

export default [
    {
        backgroundColor:  "rgba(0, 150, 136, 0.7)",
        borderColor: "rgba(0, 150, 136, 0.7)",
        end: "2023-06-12T14:00:00.000Z",
        id: "11788",
        start: "2023-06-12T13:00:00.000Z",
        textColor: "rgba(0, 0, 0, 0.9)",
        title: "Ana Almeida"
    },
    {
        backgroundColor:  "rgba(63, 81, 181, 0.7)",
        borderColor: "rgba(63, 81, 181, 0.7)",
        end: "2023-06-12T13:00:00.000Z",
        id: "11790",
        start: "2023-06-12T12:00:00.000Z",
        textColor: "rgba(0, 0, 0, 0.9)",
        title: "Juan Carlos"
    },
    {
        backgroundColor:  "rgba(33, 150, 243, 0.1)",
        borderColor: "rgba(33, 150, 243, 0.9)",
        end: "2023-06-12T17:00:00.000Z",
        id: "11789",
        start: "2023-06-12T16:45:00.000Z",
        textColor: "rgba(33, 150, 243, 0.9)",
        title: ""
    },
    {
        backgroundColor:  "rgba(63, 81, 181, 0.1)",
        borderColor: "rgba(63, 81, 181, 0.9)",
        end: "2023-06-19T11:00:00.000Z",
        id: "11791",
        start: "2023-06-19T10:00:00.000Z",
        textColor: "rgba(63, 81, 181, 0.9)",
        title: ""
    },
    {
        backgroundColor:  "rgba(0, 150, 136, 0.1)",
        borderColor: "rgba(0, 150, 136, 0.9)",
        end: "2023-06-19T14:00:00.000Z",
        id: "11792",
        start: "2023-06-19T13:00:00.000Z",
        textColor: "rgba(0, 150, 136, 0.9)",
        title: ""
    },
    {
        backgroundColor:  "rgba(0, 150, 136, 0.1)",
        borderColor: "rgba(0, 150, 136, 0.9)",
        end: "2023-06-09T14:00:00.000Z",
        id: "11793",
        start: "2023-06-09T13:00:00.000Z",
        textColor: "rgba(0, 150, 136, 0.9)",
        title: ""
    },
    {
        backgroundColor:  "rgba(0, 150, 136, 0.1)",
        borderColor: "rgba(0, 150, 136, 0.9)",
        end: "2023-06-09T15:00:00.000Z",
        id: "11794",
        start: "2023-06-09T14:00:00.000Z",
        textColor: "rgba(0, 150, 136, 0.9)",
        title: ""
    },
    {
        backgroundColor:  "rgba(0, 150, 136, 0.1)",
        borderColor: "rgba(0, 150, 136, 0.9)",
        end: "2023-06-09T16:00:00.000Z",
        id: "11795",
        start: "2023-06-09T15:00:00.000Z",
        textColor: "rgba(0, 150, 136, 0.9)",
        title: ""
    },
    {
        backgroundColor:  "rgba(0, 150, 136, 0.1)",
        borderColor: "rgba(0, 150, 136, 0.9)",
        end: "2023-06-14T14:00:00.000Z",
        id: "11796",
        start: "2023-06-14T13:00:00.000Z",
        textColor: "rgba(0, 150, 136, 0.9)",
        title: ""
    },
    {
        backgroundColor:  "rgba(63, 81, 181, 0.1)",
        borderColor: "rgba(63, 81, 181, 0.9)",
        end: "2023-06-14T15:00:00.000Z",
        id: "11797",
        start: "2023-06-14T14:00:00.000Z",
        textColor: "rgba(63, 81, 181, 0.9)",
        title: ""
    },
    {
        backgroundColor:  "rgba(63, 81, 181, 0.1)",
        borderColor: "rgba(63, 81, 181, 0.9)",
        end: "2023-06-14T16:00:00.000Z",
        id: "11798",
        start: "2023-06-14T15:00:00.000Z",
        textColor: "rgba(63, 81, 181, 0.9)",
        title: ""
    },
    {
        backgroundColor:  "rgba(63, 81, 181, 0.1)",
        borderColor: "rgba(63, 81, 181, 0.9)",
        end: "2023-06-15T15:00:00.000Z",
        id: "11799",
        start: "2023-06-15T14:00:00.000Z",
        textColor: "rgba(63, 81, 181, 0.9)",
        title: ""
    },
    {
        backgroundColor:  "rgba(63, 81, 181, 0.1)",
        borderColor: "rgba(63, 81, 181, 0.9)",
        end: "2023-06-15T16:00:00.000Z",
        id: "11800",
        start: "2023-06-15T15:00:00.000Z",
        textColor: "rgba(63, 81, 181, 0.9)",
        title: ""
    },
    {
        backgroundColor:  "rgba(0, 150, 136, 0.7)",
        borderColor: "rgba(0, 150, 136, 0.7)",
        end: "2023-06-15T14:00:00.000Z",
        id: "11801",
        start: "2023-06-15T13:00:00.000Z",
        textColor: "rgba(0, 0, 0, 0.9)",
        title: "Ana Almeida"
    },
    {
        backgroundColor:  "rgba(33, 150, 243, 0.1)",
        borderColor: "rgba(33, 150, 243, 0.9)",
        end: "2023-06-16T13:00:00.000Z",
        id: "11802",
        start: "2023-06-16T12:00:00.000Z",
        textColor: "rgba(33, 150, 243, 0.9)",
        title: ""
    },
    {
        backgroundColor:  "rgba(33, 150, 243, 0.1)",
        borderColor: "rgba(33, 150, 243, 0.9)",
        end: "2023-06-16T17:00:00.000Z",
        id: "11803",
        start: "2023-06-16T16:45:00.000Z",
        textColor: "rgba(33, 150, 243, 0.9)",
        title: ""
    },
    {
        backgroundColor:  "rgba(63, 81, 181, 0.7)",
        borderColor: "rgba(63, 81, 181, 0.7)",
        end: "2023-06-16T13:00:00.000Z",
        id: "11804",
        start: "2023-06-16T12:00:00.000Z",
        textColor: "rgba(0, 0, 0, 0.9)",
        title: "Juan Carlos"
    },
]
import React, {useEffect, useState} from 'react'
import {useNavigate, useParams, useLocation, matchPath} from 'react-router-dom'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import FormControlLabel from '@mui/material/FormControlLabel';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import MoreTime from '@mui/icons-material/MoreTime';
import Fab from '@mui/material/Fab';
import Delete from '@mui/icons-material/Delete';
import AutoFixHigh from '@mui/icons-material/AutoFixHigh';
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import InputAdornment from '@mui/material/InputAdornment';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Select, {SelectChangeEvent} from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import styled from '@emotion/styled';
import MenuTrainer from 'components/MenuTrainer'
import AvailabilityItem from 'components/AvailabilityItem'
import statuses from 'enums/statuses'
import availability from 'enums/availability'

const Text = styled.div`
    color: #708085;
    font-size: 14px;
    letter-spacing: 0.5px;
`

const Title = styled.div`
    margin-bottom: 20px;
    letter-spacing: 0.5px;
    font-weight: 500;
`

const Override = styled.div`
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 0;
`

const Slots = styled.div`
    display: flex;
    flex-direction: column;
`

const Item = styled.span`
    margin-bottom: 5px;
`

const chip = {
    letterSpacing: '0.5px',
    width: '200px',
    height: 35,
    border: '1px solid #d4d8da',

    '& .MuiChip-label': {
        padding: '0 15px'
    }
}

const TrainerAvailability = () => {
    const [openFilter, setOpenFilter] = useState<HTMLDivElement>()
    const [status, setStatus] = useState<string>(statuses.complete.value)
    const [open, setOpen] = useState(false)
    const [filter, setFilter] = useState<string>('')

    const navigate = useNavigate()
    const { pathname } = useLocation()

    const onButtonClick = () => {
        setOpen(true)
    }

    const onClose = () => {
        setOpen(false)
    }

    const onPaperClick = () => {
        navigate('/trainer/academia')
    }

    const onChipClick = (e: React.MouseEvent<HTMLDivElement>) => {
        setOpenFilter(e.currentTarget)
    }

    const handleClose = () => {
        setOpenFilter(undefined)
    }

    const onClick = () => {

    }

    return (
        <React.Fragment>
            <Drawer
                sx={{
                    width: 250
                }}
                PaperProps={{
                    sx: {
                        width: 250
                    }
                }}
                variant="permanent"
                anchor="left"
            >
            <Toolbar />
            <MenuTrainer />
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: '64px', marginLeft: '250px', minWidth: '900px'}}>
                <Paper sx={{padding: '20px', display: 'flex'}}>
                    <div style={{width: '70%', marginRight: '20px', borderRight: '1px solid #d4d8da', paddingRight: '20px'}}>
                        <Title>Working hours</Title>
                        {availability.map(item =>
                            <AvailabilityItem key={item.id} name={item.name} slots={item.slots} available={item.available} />
                        )}
                    </div>
                    <div style={{width: '30%'}}>
                        <Title style={{borderBottom: '1px solid rgb(212, 216, 218)', paddingBottom: '20px'}}>Date overrides</Title>
                        <Override>
                            <span>29 June 2023</span>
                            <Slots>
                                <Item>
                                    12:00 - 14:00
                                </Item>
                                <Item>
                                    16:00 - 18:00
                                </Item>
                            </Slots>
                            <IconButton sx={{marginTop: '-8px'}}>
                                <Delete sx={{fontSize: 20}} />
                            </IconButton>
                        </Override>
                        <Override>
                            <span>30 June 2023</span>
                            <Slots>
                                <Text>Unavailable</Text>
                            </Slots>
                            <IconButton sx={{marginTop: '-8px'}}>
                                <Delete sx={{fontSize: 20}} />
                            </IconButton>
                        </Override>
                        <div style={{marginTop: '20px'}}>
                            <Chip label="Add a date override" icon={<MoreTime />} onClick={onClick} sx={chip} />
                        </div>
                    </div>
                </Paper>
            </Box>
        </React.Fragment>
    )
}

export default TrainerAvailability

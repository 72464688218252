import React, {useEffect, useState} from 'react'
import {matchPath, useLocation} from 'react-router-dom'
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checklist from '@mui/icons-material/Checklist';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import Addchart from '@mui/icons-material/Addchart';
import Schedule from '@mui/icons-material/Schedule';

const menu = [
    {
        title: 'Commissions',
        value: 'commissions'
    }, {
        title: 'Project Invoices',
        value: 'projects'
    }, {
        title: 'Academia Invoices',
        value: 'academias'
    }, {
        title: 'Trainers Summary',
        value: 'trainers'
    }
]

const MenuManager = () => {
    const { pathname } = useLocation()

    return (
        <React.Fragment>
           <Divider />
            <List>   
                {menu.map((item, index) => (
                     <ListItem key={index} disablePadding>
                        <ListItemButton disableRipple href={`/manager/${item.value}`} selected={!!matchPath(`/manager/${item.value}`, pathname)}>
                            <ListItemIcon>
                                {index % 2 === 0 ? <Checklist /> : <Schedule />}
                            </ListItemIcon>
                            <ListItemText primary={item.title} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </React.Fragment>
    )
}

export default MenuManager

export default [
    {
        id: 1,
        name: 'Monday',
        available: true,
        slots: [
            {
                start: '10:00',
                end: '18:00',
                id: 1
            }
        ]
    },
    {
        id: 2,
        name: 'Tuesday',
        available: true,
        slots: [
            {
                start: '10:00',
                end: '14:00',
                id: 1
            },
            {
                start: '16:00',
                end: '18:00',
                id: 2
            }
        ]
    },
    {
        id: 3,
        name: 'Wednesday',
        available: true,
        slots: [
            {
                start: '10:00',
                end: '18:00',
                id: 1
            }
        ]
    },
    {
        id: 4,
        name: 'Thursday',
        available: true,
        slots: [
            {
                start: '10:00',
                end: '12:00',
                id: 1
            },
            {
                start: '14:00',
                end: '18:00',
                id: 2
            }
        ]
    },
    {
        id: 5,
        name: 'Friday',
        available: true,
        slots: [
            {
                start: '10:00',
                end: '18:00',
                id: 1
            }
        ]
    },
    {
        id: 6,
        name: 'Saturday',
        available: false,
        slots: []
    },
    {
        id: 7,
        name: 'Sanday',
        available: false,
        slots: []
    }
]
import React, {useEffect, useState} from 'react'
import {useNavigate, useParams, useLocation, matchPath} from 'react-router-dom'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import Chip from '@mui/material/Chip';
import Toolbar from '@mui/material/Toolbar';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import styled from '@emotion/styled';
import MenuTrainer from 'components/MenuTrainer'
import TrainerSummaryItem from 'components/TrainerSummaryItem'
import Breadcrumbs from 'components/Breadcrumbs'
import summary from 'enums/summary'

const Table = styled.div`
    overflow: auto;
`

const Header = styled.div`
    display: flex;
    top: 0px;
    position: sticky;
    z-index: 3;
    background-color: #f0f2f2;
    letter-spacing: 0.5px;
    color: #708085;
    font-size: 13px;
    border-bottom: 1px solid #d4d8da;
    background-color: #f0f2f2;
    min-width: 1100px;
    width: 100%;
`

const Cell = styled.div<{width?: number}>`
    box-sizing: border-box;
    width: ${props => props.width ? props.width : 200}px;
    padding: 20px;
    font-size: 14px;
    
    :not(:last-child) {
        border-right: 1px solid #d4d8da;
    }
`

const months = [
    {
        id: 1,
        name: 'April'
    },
    {
        id: 2,
        name: 'May'
    },
    {
        id: 3,
        name: 'June'
    }
]

const TrainerSummary = () => {
    const [filter, setFilter] = useState<string>('May')
    const [openFilter, setOpenFilter] = useState<HTMLDivElement>()

    const navigate = useNavigate()
    const { pathname } = useLocation()

    const onChipClick = (e: React.MouseEvent<HTMLDivElement>) => {
        setOpenFilter(e.currentTarget)
    }

    const handleClose = () => {
        setOpenFilter(undefined)
    }

    const onMenuItemClick = (value: string) => {
        if (value === filter) {
            setFilter('')
        } else {
            setFilter(value)
        }
        
        handleClose()
    }

    return (
        <React.Fragment>
            <Drawer
                sx={{
                    width: 250
                }}
                PaperProps={{
                    sx: {
                        width: 250
                    }
                }}
                variant="permanent"
                anchor="left"
            >
            <Toolbar />
            <MenuTrainer />
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: '64px', marginLeft: '250px'}}>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '20px'}}>
                <Alert
                    sx={{'& .MuiAlert-action': {
                        padding: '0 0 0 100px'
                      }}}
                    elevation={1} severity="warning"
                    icon={<ErrorOutline fontSize="inherit" sx={{color: '#ff9800'}} />}
                    action={
                        <Button size="small" variant='contained' color='success'>
                            Confirm
                        </Button>
                    }
                >
                    Check the list of your sessions in May and confirm if data is correct
                </Alert>
                   <div style={{display: 'flex', alignItems: 'center'}}>
                        <Chip icon={<CalendarMonth sx={{fontSize: 18}}/>}
                        label={filter ? filter : 'Month'}
                        color={filter ? 'primary' : 'default'}
                        sx={{marginLeft: 'auto', cursor: 'pointer', width: '100px'}} onClick={onChipClick} />
                </div>
                </div>
                <Paper>
                    <Table>
                        <Header>
                            <Cell>Academia</Cell>
                            <Cell>Trainee</Cell>
                            <Cell>Area</Cell>
                            <Cell>Date</Cell>
                            <Cell width={300}>Status</Cell>
                        </Header>
                        {summary.map((item, index) => (
                            <TrainerSummaryItem key={index} area={item.area} date={item.date} academia={item.academia}
                            trainee={item.trainee} status={item.status}></TrainerSummaryItem>
                        ))}
                    </Table>
                </Paper>
            </Box>
            <Menu
                anchorEl={openFilter}
                open={!!openFilter}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{sx: {
                    marginTop: '10px'
                }}}
            >
                {months.map(item => (
                    <MenuItem onClick={() => onMenuItemClick(item.name)} sx={{width: '100px'}}
                        key={item.name}
                        value={item.name}
                    >
                        {item.name}
                    </MenuItem>
                ))}
            </Menu>
        </React.Fragment>
    )
}

export default TrainerSummary

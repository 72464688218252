import React, {useEffect, useState} from 'react'
import {useNavigate, useParams, useLocation, matchPath} from 'react-router-dom'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ChecklistIcon from '@mui/icons-material/Checklist';
import BarChart from '@mui/icons-material/BarChart';
import Insights from '@mui/icons-material/Insights';
import Schedule from '@mui/icons-material/Schedule';
import styled from '@emotion/styled';
import MenuTrainer from 'components/MenuTrainer'
import TraineesItem from 'components/TraineesItem'
import Breadcrumbs from 'components/Breadcrumbs'
import trainerTrainees from 'enums/trainerTrainees'

const Table = styled.div`
    overflow: auto;
`

const Header = styled.div`
    display: flex;
    top: 0px;
    position: sticky;
    z-index: 3;
    background-color: #f0f2f2;
    letter-spacing: 0.5px;
    color: #708085;
    font-size: 13px;
    border-bottom: 1px solid #d4d8da;
    background-color: #f0f2f2;
    min-width: 1900px;
    width: 100%;
`

const Cell = styled.div<{width?: number}>`
    box-sizing: border-box;
    width: ${props => props.width ? props.width : 200}px;
    padding: 20px;
    font-size: 14px;
    
    :not(:last-child) {
        border-right: 1px solid #d4d8da;
    }
`

const TrainerTrainees = () => {
    const { pathname } = useLocation()

    return (
        <React.Fragment>
            <Drawer
                sx={{
                    width: 250
                }}
                PaperProps={{
                    sx: {
                        width: 250
                    }
                }}
                variant="permanent"
                anchor="left"
            >
            <Toolbar />
            <Divider />
            <List>
                {['Sessions', 'Trainees'].map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton disableRipple href={`/trainer/${text.toLocaleLowerCase()}`} selected={!!matchPath(`/trainer/${text.toLocaleLowerCase()}`, pathname)}>
                            <ListItemIcon>
                                {index % 2 === 0 ? <ChecklistIcon /> : <Schedule />}
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <MenuTrainer />
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: '64px', marginLeft: '250px'}}>
            <Breadcrumbs academia></Breadcrumbs>
                <Paper>
                    <Table>
                        <Header>
                            <Cell width={300}>Name</Cell>
                            <Cell>Photo</Cell>
                            <Cell>Phone</Cell>
                            <Cell>Email</Cell>
                            <Cell width={300}>Presentation video</Cell>
                            <Cell width={300}>Final report</Cell>
                            <Cell>Company</Cell>
                            <Cell>Current role</Cell>
                        </Header>
                        {trainerTrainees.map((item, index) => (
                            <TraineesItem key={index} name={item.name} email={item.email} photo={item.photo}
                            phone={item.phone} presentation={item.presentation} final={item.final} company={item.company} role={item.role} />
                        ))}
                    </Table>
                </Paper>
            </Box>
        </React.Fragment>
    )
}

export default TrainerTrainees

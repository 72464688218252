export default [
    {
        title: 'Tickets',
        price: '200',
        units: '8',
        total: '1600'
    },
    {
        title: 'Hotel rooms',
        price: '100',
        units: '8',
        total: '800'
    },
    {
        title: 'Transfer',
        price: '100',
        units: '1',
        total: '100'
    },
    {
        title: 'Tickets',
        price: '200',
        units: '8',
        total: '1600'
    },
    {
        title: 'Hotel rooms',
        price: '100',
        units: '8',
        total: '800'
    },
    {
        title: 'Transfer',
        price: '100',
        units: '1',
        total: '100'
    }
]
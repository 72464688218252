import React, {useEffect, useState} from 'react'
import {useNavigate, useParams, useLocation, useOutletContext} from 'react-router-dom'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MailIcon from '@mui/icons-material/Mail';
import Chip from '@mui/material/Chip';
import styled from '@emotion/styled'

type Members = {
    [key: string]: {
        name: string,
        color: string
    }
}

const members: Members = {
    "Maria Oliveira": {
        name: "Maria Oliveira",
        color: '#b2ebf2'
    },
    "João Pereira": {
        name: "João Pereira",
        color: '#d1c4e9'
    }
}

const Container = styled.div`
    display: flex;
    border-bottom: 1px solid #d4d8da;
    min-width: 1200px;
    width: 100%;
    align-items: stretch;
`

const Cell = styled.div<{width?: number}>`
    box-sizing: border-box;
    width: ${props => props.width ? props.width : 200}px;
    padding: 10px 20px;
    font-size: 14px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    
    :not(:last-child) {
        border-right: 1px solid #d4d8da;
    }
`

const Input = styled.div<{width?: number}>`
    box-sizing: border-box;
    width: ${props => props.width ? props.width : 200}px;
    padding: 10px 20px;
    font-size: 14px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    position: relative;
    line-height: 40px;
    
    :not(:last-child) {
        border-right: 1px solid #d4d8da;
    }

    :focus {
        outline: 0;

        :after {
            outline: #0084c3 solid 2px;
            border-radius: 2px;
            z-index: 4;
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            cursor: text;
        }
    }
`

const chip = {
    marginBottom: '10px',
    marginRight: '10px'
}

const input = {
    marginBottom: '10px',
    marginTop: '10px'
}

interface IComponent {
    member: string,
    project: string,
    client: string,
    comission: string,
    value: string
}

type Statuses = {
    [key: string]: {
        name: string,
        color: string
    }
}

const Item = (props: IComponent) => {
    return (
        <Container>
            <Cell><Chip label={members[props.member].name} sx={{background: members[props.member].color}} /></Cell>
            <Cell width={400}>{props.project}</Cell>
            <Cell>{props.client}</Cell>
            <Input contentEditable suppressContentEditableWarning>{props.comission}</Input>
            <Cell>€ {props.value}</Cell>
        </Container>
    )
}

export default Item

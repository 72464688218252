import React, {useEffect, useState} from 'react'
import {useNavigate, useParams, useLocation, matchPath} from 'react-router-dom'
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import GroupIcon from '@mui/icons-material/Group';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import PersonIcon from '@mui/icons-material/Person';
import AutoFixHigh from '@mui/icons-material/AutoFixHigh';
import styled from '@emotion/styled';
import MenuManager from 'components/MenuManager'
import Item from './Item'
import academiaInvoices from 'enums/academiaInvoices'

const Table = styled.div`
    overflow: auto;
`

const Header = styled.div`
    display: flex;
    top: 0px;
    position: sticky;
    z-index: 3;
    background-color: #f0f2f2;
    letter-spacing: 0.5px;
    color: #708085;
    font-size: 13px;
    border-bottom: 1px solid #d4d8da;
    background-color: #f0f2f2;
    min-width: 1400px;
    width: 100%;
`

const Cell = styled.div<{width?: number}>`
    box-sizing: border-box;
    width: ${props => props.width ? props.width : 200}px;
    padding: 20px;
    font-size: 14px;
    
    :not(:last-child) {
        border-right: 1px solid #d4d8da;
    }
`

const CellFooter = styled.div<{width?: number}>`
    box-sizing: border-box;
    width: ${props => props.width ? props.width : 200}px;
    padding: 20px;
    font-size: 14px;
    display: flex;
    align-items: center;
    
    :not(:last-child) {
        border-right: 1px solid #d4d8da;
    }

    color: #2e7d32;
    font-weight: 600;
`

const Footer = styled.div`
    display: flex;
    bottom: 0px;
    position: sticky;
    z-index: 3;
    background-color: #f0f2f2;
    letter-spacing: 0.5px;
    color: #708085;
    font-size: 13px;
    border-bottom: 1px solid #d4d8da;
    background-color: #fcfcfc;
    min-width: 1400px;
    width: 100%;
`

const members = [
    {
        id: 1,
        name: 'Juan Carlos'
    },
    {
        id: 2,
        name: 'Ana Almeida'
    },
    {
        id: 3,
        name: 'Maria Santos'
    }
]

const AcademiaInvoices = () => {
    const [filter, setFilter] = useState<string>('')
    const [openFilter, setOpenFilter] = useState<HTMLDivElement>()

    const { pathname } = useLocation()

    const onChipClick = (e: React.MouseEvent<HTMLDivElement>) => {
        setOpenFilter(e.currentTarget)
    }

    const handleClose = () => {
        setOpenFilter(undefined)
    }

    const onMenuItemClick = (value: string) => {
        if (value === filter) {
            setFilter('')
        } else {
            setFilter(value)
        }
        
        handleClose()
    }

    return (
        <React.Fragment>
            <Drawer
                sx={{
                    width: 250
                }}
                PaperProps={{
                    sx: {
                        width: 250
                    }
                }}
                variant="permanent"
                anchor="left"
            >
            <Toolbar />
            <MenuManager></MenuManager>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: '64px', marginLeft: '250px'}}>
                <div style={{display: 'flex', marginBottom: '20px'}}>
                   <div style={{display: 'flex', alignItems: 'center', marginLeft: 'auto'}}>
                   <Chip icon={<PersonIcon sx={{fontSize: 18}}/>}
                        label={filter ? filter : 'Client'}
                        color={filter ? 'primary' : 'default'}
                        sx={{cursor: 'pointer', width: '150px'}} onClick={onChipClick} />
                        <Chip icon={<AutoFixHigh sx={{fontSize: 18}}/>} label="Invoice Status" sx={{width: '150px', cursor: 'pointer', margin: '0 20px'}} />
                        <Chip icon={<AutoFixHigh sx={{fontSize: 18}}/>} label="Academia" sx={{width: '150px', cursor: 'pointer'}} />
                    </div>
                </div>
                <Paper>
                    <Table>
                        <Header>
                            <Cell>Academia</Cell>
                            <Cell>Client</Cell>
                            <Cell>Invoice rule</Cell>
                            <Cell>Enroll date</Cell>
                            <Cell>Due date</Cell>
                            <Cell>Invoice value</Cell>
                            <Cell>Invoice status</Cell>
                        </Header>
                        {academiaInvoices.map((item, index) => (
                            <Item key={index} academia={item.academia} enrollDate={item.enrollDate} client={item.client}
                            rule={item.rule} dueDate={item.dueDate} invoice={item.invoice} stage={item.stage}></Item>
                        ))}
                        <Footer>
                            <CellFooter></CellFooter>
                            <CellFooter></CellFooter>
                            <CellFooter></CellFooter>
                            <CellFooter></CellFooter>
                            <CellFooter></CellFooter>
                            <CellFooter>€ 6 000</CellFooter>
                            <CellFooter></CellFooter>
                        </Footer>
                    </Table>
                </Paper>
            </Box>
            <Menu
                anchorEl={openFilter}
                open={!!openFilter}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{sx: {
                    marginTop: '10px'
                }}}
            >
                {members.map(item => (
                    <MenuItem onClick={() => onMenuItemClick(item.name)} sx={{width: '150px'}}
                        key={item.name}
                        value={item.name}
                    >
                        {item.name}
                    </MenuItem>
                ))}
            </Menu>
        </React.Fragment>
    )
}

export default AcademiaInvoices

export default [
    {
        name: 'Juan Carlos',
        id: 1,
        phone: '+351 920 506 891',
        email: 'juan.carlos@gmail.com',
        photo: 'photo',
        presentation: 'drive.google.com/drive/my-drive',
        final: 'drive.google.com/drive/my-drive',
        company: 'Altice',
        role: 'Key account manager'
    },
    {
        name: 'Ana Patricia Santos',
        id: 2,
        phone: '+351 920 506 891',
        email: 'ana.santos@gmail.com',
        photo: '',
        presentation: '',
        final: '',
        company: 'Siemens',
        role: 'Head of Finance'
    },
    {
        name: 'Ana Almeida',
        id: 3,
        phone: '+351 920 506 891',
        email: 'ana.almeida@gmail.com',
        photo: '',
        presentation: '',
        final: 'drive.google.com/drive/my-drive',
        company: 'Auchan Portugal',
        role: 'Training Coordinator'
    },
    {
        name: 'Ana Almeida',
        id: 4,
        phone: '+351 920 506 891',
        email: 'ana.almeida@gmail.com',
        photo: '',
        presentation: '',
        final: 'drive.google.com/drive/my-drive',
        company: 'Altice',
        role: 'Key account manager'
    },
    {
        name: 'Ana Patricia Santos',
        id: 5,
        phone: '+351 920 506 891',
        email: 'ana.santos@gmail.com',
        photo: '',
        presentation: '',
        final: '',
        company: 'Altice',
        role: 'Key account manager'
    },
    {
        name: 'Juan Carlos',
        id: 6,
        phone: '+351 920 506 891',
        email: 'juan.carlos@gmail.com',
        photo: 'photo',
        presentation: '',
        final: 'drive.google.com/drive/my-drive',
        company: 'Altice',
        role: 'Key account manager'
    },
    {
        name: 'Ana Patricia Santos',
        id: 7,
        phone: '+351 920 506 891',
        email: 'ana.almeida@gmail.com',
        photo: '',
        presentation: '',
        final: 'drive.google.com/drive/my-drive',
        company: 'Altice',
        role: 'Key account manager'
    },
    {
        name: 'Juan Carlos',
        id: 1,
        phone: '+351 920 506 891',
        email: 'juan.carlos@gmail.com',
        photo: 'photo',
        presentation: 'drive.google.com/drive/my-drive',
        final: '',
        company: 'Altice',
        role: 'Key account manager'
    },
    {
        name: 'Ana Patricia Santos',
        id: 2,
        phone: '+351 920 506 891',
        email: 'ana.santos@gmail.com',
        photo: '',
        presentation: '',
        final: 'drive.google.com/drive/my-drive',
        company: 'Altice',
        role: 'Key account manager'
    },
    {
        name: 'Ana Almeida',
        id: 3,
        phone: '+351 920 506 891',
        email: 'ana.almeida@gmail.com',
        photo: '',
        presentation: 'drive.google.com/drive/my-drive',
        final: '',
        company: 'Altice',
        role: 'Key account manager'
    },
    {
        name: 'Ana Almeida',
        id: 4,
        phone: '+351 920 506 891',
        email: 'ana.almeida@gmail.com',
        photo: '',
        presentation: '',
        final: '',
        company: 'Altice',
        role: 'Key account manager'
    },
    {
        name: 'Ana Patricia Santos',
        id: 5,
        phone: '+351 920 506 891',
        email: 'ana.santos@gmail.com',
        photo: '',
        presentation: '',
        final: '',
        company: 'Altice',
        role: 'Key account manager'
    },
    {
        name: 'Juan Carlos',
        id: 6,
        phone: '+351 920 506 891',
        email: 'juan.carlos@gmail.com',
        photo: 'photo',
        presentation: '',
        final: '',
        company: 'Altice',
        role: 'Key account manager'
    },
    {
        name: 'Ana Patricia Santos',
        id: 7,
        phone: '+351 920 506 891',
        email: 'ana.almeida@gmail.com',
        photo: '',
        presentation: '',
        final: '',
        company: 'Altice',
        role: 'Key account manager'
    },
]
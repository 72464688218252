export default [
    {
        trainer: 'Eduardo Cabrita',
        date: '24.07.2022',
        area: 'media',
        trainee: 'Ana Almeida',
        status: 'scheduled',
        report: ''
    },
    {
        trainer: 'Maria Simoes',
        date: '24.07.2022',
        area: 'media',
        trainee: 'Juan Carlos',
        status: 'done',
        report: 'Lorem ipsum dolor sit amet'
    },
    {
        trainer: 'Maria Oliveira',
        date: '24.07.2022',
        area: 'story',
        trainee: 'Ana Patricia Santos',
        status: 'canceled',
        report: ''
    },
    {
        trainer: 'Eduardo Cabrita',
        date: '24.07.2022',
        area: 'media',
        trainee: 'Ana Almeida',
        status: 'last',
        report: ''
    },
    {
        trainer: 'Maria Simoes',
        date: '24.07.2022',
        area: 'media',
        trainee: 'Juan Carlos',
        status: 'scheduled',
        report: ''
    },
    {
        trainer: 'Maria Oliveira',
        date: '',
        area: 'story',
        trainee: 'Ana Patricia Santos',
        status: '',
        report: ''
    },
    {
        trainer: 'Eduardo Cabrita',
        date: '24.07.2022',
        area: 'media',
        trainee: 'Ana Almeida',
        status: 'done',
        report: 'Lorem ipsum dolor sit amet'
    },
    {
        trainer: 'Maria Simoes',
        date: '24.07.2022',
        area: 'image',
        trainee: 'Juan Carlos',
        status: 'done',
        report: 'Lorem ipsum dolor sit amet'
    },
    {
        trainer: 'Maria Oliveira',
        date: '24.07.2022',
        area: 'story',
        trainee: 'Ana Patricia Santos',
        status: 'scheduled',
        report: ''
    },
    {
        trainer: 'Eduardo Cabrita',
        date: '',
        area: 'media',
        trainee: 'Ana Almeida',
        status: '',
        report: ''
    },
    {
        trainer: 'Maria Simoes',
        date: '24.07.2022',
        area: 'media',
        trainee: 'Juan Carlos',
        status: 'done',
        report: 'Lorem ipsum dolor sit amet'
    },
    {
        trainer: 'Maria Oliveira',
        date: '24.07.2022',
        area: 'story',
        trainee: 'Ana Patricia Santos',
        status: 'done',
        report: 'Lorem ipsum dolor sit amet'
    },
    {
        trainer: 'Eduardo Cabrita',
        date: '24.07.2022',
        area: 'media',
        trainee: 'Ana Almeida',
        status: 'done',
        report: 'Lorem ipsum dolor sit amet'
    },
    {
        trainer: 'Maria Simoes',
        date: '24.07.2022',
        area: 'image',
        trainee: 'Juan Carlos',
        status: 'scheduled',
        report: ''
    },
    {
        trainer: 'Maria Oliveira',
        date: '24.07.2022',
        area: 'story',
        trainee: 'Ana Patricia Santos',
        status: 'done',
        report: 'Lorem ipsum dolor sit amet'
    },
    {
        trainer: 'Eduardo Cabrita',
        date: '24.07.2022',
        area: 'media',
        trainee: 'Ana Almeida',
        status: 'scheduled',
        report: ''
    },
    {
        trainer: 'Maria Simoes',
        date: '24.07.2022',
        area: 'media',
        trainee: 'Juan Carlos',
        status: 'scheduled',
        report: ''
    },
    {
        trainer: 'Maria Oliveira',
        date: '24.07.2022',
        area: 'story',
        trainee: 'Ana Patricia Santos',
        status: 'done',
        report: 'Lorem ipsum dolor sit amet'
    }
]
import React, {useEffect, useState} from 'react'
import {useNavigate, useParams, useLocation, useOutletContext} from 'react-router-dom'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MailIcon from '@mui/icons-material/ForwardToInbox';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import styled from '@emotion/styled'

type Statuses = {
    [key: string]: {
        name: string,
        color: string
    }
}

const statuses: Statuses = {
    accepted: {
        name: "Accepted",
        color: '#c30084'
    },
    completed: {
        name: "Completed",
        color: '#2e7d32'
    }
}

const stages: Statuses = {
    ready: {
        name: "Ready",
        color: '#e1bee7'
    },
    notReady: {
        name: "Not ready",
        color: '#ffcdd2'
    },
    sent: {
        name: "Sent",
        color: '#c8e6c9'
    }
}

const Container = styled.div`
    display: flex;
    border-bottom: 1px solid #d4d8da;
    min-width: 1600px;
    width: 100%;
    align-items: stretch;
    height: 53px;
`

const Cell = styled.div<{width?: number}>`
    box-sizing: border-box;
    width: ${props => props.width ? props.width : 200}px;
    padding: 10px 20px;
    font-size: 14px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    
    :not(:last-child) {
        border-right: 1px solid #d4d8da;
    }
`

const chip = {
    marginBottom: '10px',
    marginRight: '10px'
}

const input = {
    marginBottom: '10px',
    marginTop: '10px'
}

interface IComponent {
    status: string,
    project: string,
    client: string,
    rule: string,
    total: string,
    invoice: string,
    stage: string
}

const Item = (props: IComponent) => {
    return (
        <Container>
            <Cell width={400}>{props.project}</Cell>
            <Cell>{props.client}</Cell>
            <Cell>{props.rule}</Cell>
            <Cell><Chip label={statuses[props.status].name} sx={{background: statuses[props.status].color, color: '#ffffff'}} /></Cell>
            <Cell>€ {props.total}</Cell>
            <Cell>{props.invoice ? '€' : ''} {props.invoice}</Cell>
            <Cell>
                <Chip label={stages[props.stage].name} sx={{background: stages[props.stage].color, width: 100}} />
                {props.stage === 'ready' &&
                    <Tooltip title="Send" placement="bottom-start" sx={{marginLeft: 'auto'}}>
                        <IconButton>
                            <MailIcon sx={{fontSize: 20}} />
                        </IconButton>
                    </Tooltip>    
                }
            </Cell>
        </Container>
    )
}

export default Item

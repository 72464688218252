import {createTheme} from '@mui/material/styles'

const theme = createTheme({
    typography: {
        fontFamily: 'Helvetica Neue, Arial, sans-serif'
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    minWidth: 100,
                    textTransform: 'none',
                    letterSpacing: '1px',
                    lineHeight: 2,
                    fontWeight: 400,
                    borderRadius: '5px'
                }
            }
        }
    },
    palette: {
        mode: 'light',
        primary: {
            light: '#c3ecff',
            main: '#0084c3',
            dark: '#006190'
        },
        secondary: {
            main: '#c30084',
        },
        info: {
            light: '#eeeeee',
            main: '#ffffff',
            dark: '#d4d4d4'
        },
        warning: {
            main: '#ffeb3b'
        },
    }
})

export default theme
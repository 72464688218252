import React, {useEffect, useState} from 'react'
import {useNavigate, useParams, useLocation, useOutletContext} from 'react-router-dom'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MailIcon from '@mui/icons-material/Mail';
import Chip from '@mui/material/Chip';
import styled from '@emotion/styled';
import contacts from 'enums/contacts'

type Types = {
    [key: string]: {
        name: string,
        color: string
    }
}

const types: Types = {
    story: {
        name: "Storytelling",
        color: '#c5cae9'
    },
    image: {
        name: "Image and Success",
        color: '#bbdefb'
    },
    media: {
        name: "Media Training",
        color: '#b2dfdb'
    }
}

const Container = styled.div`
    display: flex;
    border-bottom: 1px solid #d4d8da;
    min-width: 600px;
    width: 100%;
    align-items: stretch;
`

const Cell = styled.div<{width?: number}>`
    box-sizing: border-box;
    width: ${props => props.width ? props.width : 200}px;
    padding: 10px 20px;
    font-size: 14px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    
    :not(:last-child) {
        border-right: 1px solid #d4d8da;
    }
`

const chip = {
    marginBottom: '10px',
    marginRight: '10px'
}

const input = {
    marginBottom: '10px',
    marginTop: '10px'
}

interface IComponent {
    area: string,
    trainer: string,
    status: string
}

type Statuses = {
    [key: string]: {
        name: string,
        color: string
    }
}

const statuses: Statuses = {
    invited: {
        name: "Invited",
        color: '#fff9c4'
    },
    accepted: {
        name: "Accepted",
        color: '#c8e6c9'
    },
    refused: {
        name: "Refused",
        color: '#ffcdd2'
    }
}

const AcademiaSessionsItem = (props: IComponent) => {
    return (
        <Container>
            <Cell><Chip label={types[props.area].name} sx={{background: types[props.area].color}} /></Cell>
            <Cell>{props.trainer}</Cell>
            <Cell><Chip label={statuses[props.status].name} sx={{background: statuses[props.status].color}} /></Cell>
        </Container>
    )
}

export default AcademiaSessionsItem

import React, {useEffect, useState} from 'react'
import {useNavigate, useParams, useLocation, matchPath} from 'react-router-dom'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Autocomplete from '@mui/material/Autocomplete';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import TaskAlt from '@mui/icons-material/TaskAlt';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MailIcon from '@mui/icons-material/Mail';
import Chip from '@mui/material/Chip';
import GroupIcon from '@mui/icons-material/Group';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import AutoFixHigh from '@mui/icons-material/AutoFixHigh';
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Select, {SelectChangeEvent} from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import styled from '@emotion/styled';
import MenuTrainer from 'components/MenuTrainer'
import statuses from 'enums/statuses'
import academias from 'enums/academias'

type colors = 'success' | 'error' | 'secondary'

const Title = styled.div`
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    margin-bottom: 10px;
    color: #708085;
`

const Item = styled.div`
    font-size: 13px;
    margin-bottom: 20px;
`

const chip = {
    marginBottom: '10px',
    marginRight: '10px'
}

const input = {
    marginBottom: '15px',
    marginTop: '15px'
}

const paper = {
    flex: '0 0 calc(25% - 15px)',
    padding: '20px',
    marginBottom: '20px',
    cursor: 'pointer'
}

const Trainer = () => {
    const [openFilter, setOpenFilter] = useState<HTMLDivElement>()
    const [status, setStatus] = useState<string>(statuses.complete.value)
    const [open, setOpen] = useState(false)
    const [filter, setFilter] = useState<string>('')

    const navigate = useNavigate()
    const { pathname } = useLocation()

    const onButtonClick = () => {
        setOpen(true)
    }

    const onClose = () => {
        setOpen(false)
    }

    const onPaperClick = () => {
        navigate('/trainer/sessions')
    }

    const onChipClick = (e: React.MouseEvent<HTMLDivElement>) => {
        setOpenFilter(e.currentTarget)
    }

    const handleClose = () => {
        setOpenFilter(undefined)
    }

    const filterItem = Object.values(statuses).find(item => item.value === filter)

    return (
        <React.Fragment>
            <Drawer
                sx={{
                    width: 250
                }}
                PaperProps={{
                    sx: {
                        width: 250
                    }
                }}
                variant="permanent"
                anchor="left"
            >
            <Toolbar />
            <MenuTrainer />
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: '64px', marginLeft: '250px'}}>
                <div style={{display: 'flex'}}>
                    <Chip icon={<TaskAlt sx={{fontSize: 18}}/>}
                    label={filter ? filter : 'Status'}
                    color={filterItem ? filterItem.color as colors : 'default'}
                    sx={{margin: '0 0 20px auto', cursor: 'pointer', width: '100px'}} onClick={onChipClick} />
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', minWidth: '900px'}}>
                    {academias.map(academia =>
                        <Paper sx={paper} onClick={onPaperClick} key={academia.id} elevation={2}>
                            <div style={{marginBottom: '20px', overflow: 'hidden'}}>{academia.name}</div>
                            <Item>
                                <Title>
                                    <CalendarMonthIcon sx={{marginRight: '5px', fontSize: 18}} /> Start date
                                </Title>
                                <div>{academia.start}</div>
                            </Item>
                            <Item>
                                <Title>
                                    <CalendarMonthIcon sx={{marginRight: '5px', fontSize: 18}} /> End date
                                </Title>
                                <div>{academia.end}</div>
                            </Item>
                            <Item>
                                <Title>
                                    <TaskAlt sx={{marginRight: '5px', fontSize: 18}} /> Status
                                </Title>
                                <div>
                                    <Chip label={statuses[academia.status].value} color={statuses[academia.status].color as colors} />
                                </div>
                            </Item>
                        </Paper>
                    )}
                </div>
            </Box>
        <Menu
            anchorEl={openFilter}
            open={!!openFilter}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              PaperProps={{sx: {
                marginTop: '10px'
              }}}
      >
        {Object.values(statuses).map(item => (
            <MenuItem onClick={() => {
                setFilter(item.value)
                handleClose()
            }}
                key={item.value}
                value={item.value}
            >
                {item.value}
            </MenuItem>
        ))}
      </Menu>
        </React.Fragment>
    )
}

export default Trainer

import React, {useEffect, useState} from 'react'
import {matchPath, useLocation} from 'react-router-dom'
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BarChart from '@mui/icons-material/BarChart';
import Insights from '@mui/icons-material/Insights';
import Addchart from '@mui/icons-material/Addchart';
import Schedule from '@mui/icons-material/Schedule';

const Menu = () => {
    const { pathname } = useLocation()

    return (
        <React.Fragment>
            <Divider />
            <List>
                {['Projects', 'Academias', 'Forecast'].map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton href={`/${text.toLocaleLowerCase()}`} selected={!!matchPath(text.toLocaleLowerCase(), pathname)}>
                            <ListItemIcon>
                                {index % 2 === 0 ? <BarChart /> : <Insights />}
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </React.Fragment>
    )
}

export default Menu

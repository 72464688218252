export default [
    {
        backgroundColor:  "rgba(0, 150, 136, 0.7)",
        borderColor: "rgba(0, 150, 136, 0.7)",
        end: "2023-06-03T14:00:00.000Z",
        id: "11788",
        start: "2023-06-03T13:00:00.000Z",
        textColor: "rgba(0, 0, 0, 0.9)",
        title: "Eduardo Cabrita"
    },
    {
        backgroundColor:  "rgba(33, 150, 243, 0.1)",
        borderColor: "rgba(33, 150, 243, 0.9)",
        end: "2023-06-03T17:00:00.000Z",
        id: "11789",
        start: "2023-06-03T16:45:00.000Z",
        textColor: "rgba(33, 150, 243, 0.9)",
        title: "Maria Simoes"
    },
    {
        backgroundColor:  "rgba(63, 81, 181, 0.7)",
        borderColor: "rgba(63, 81, 181, 0.7)",
        end: "2023-06-12T13:00:00.000Z",
        id: "11790",
        start: "2023-06-12T12:00:00.000Z",
        textColor: "rgba(0, 0, 0, 0.9)",
        title: "Maria Oliveira"
    },
    {
        backgroundColor:  "rgba(63, 81, 181, 0.1)",
        borderColor: "rgba(63, 81, 181, 0.9)",
        end: "2023-06-24T11:00:00.000Z",
        id: "11791",
        start: "2023-06-24T10:00:00.000Z",
        textColor: "rgba(63, 81, 181, 0.9)",
        title: "Maria Oliveira"
    },
    {
        backgroundColor:  "rgba(0, 150, 136, 0.1)",
        borderColor: "rgba(0, 150, 136, 0.9)",
        end: "2023-06-18T14:00:00.000Z",
        id: "11792",
        start: "2023-06-18T13:00:00.000Z",
        textColor: "rgba(0, 150, 136, 0.9)",
        title: "Eduardo Cabrita"
    },
    {
        backgroundColor:  "rgba(33, 150, 243, 0.1)",
        borderColor: "rgba(33, 150, 243, 0.9)",
        end: "2023-06-06T17:00:00.000Z",
        id: "11793",
        start: "2023-06-06T16:45:00.000Z",
        textColor: "rgba(33, 150, 243, 0.9)",
        title: "Maria Simoes"
    },
]
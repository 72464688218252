export default [
    {
        status: 'accepted',
        project: 'Communication Techniques and Public Speaking',
        client: 'Argo Partners',
        rule: 'Accepted - 70%',
        total: '10 000',
        invoice: '7 000',
        stage: 'ready'
    },
    {
        status: 'accepted',
        project: 'Communication Techniques and Public Speaking',
        client: 'Argo Partners',
        rule: 'Completed - 30%',
        total: '10 000',
        invoice: '',
        stage: 'notReady'
    },
    {
        status: 'completed',
        project: 'Lecture Communicating is creating connection',
        client: 'Galp',
        rule: 'Accepted - 70%',
        total: '20 000',
        invoice: '14 000',
        stage: 'sent'
    },
    {
        status: 'completed',
        project: 'Lecture Communicating is creating connection',
        client: 'Galp',
        rule: 'Completed - 30%',
        total: '20 000',
        invoice: '6 000',
        stage: 'sent'
    },
    {
        status: 'accepted',
        project: 'Communication Techniques and Public Speaking',
        client: 'Argo Partners',
        rule: 'Accepted - 70%',
        total: '10 000',
        invoice: '7 000',
        stage: 'ready'
    },
    {
        status: 'accepted',
        project: 'Communication Techniques and Public Speaking',
        client: 'Argo Partners',
        rule: 'Completed - 30%',
        total: '10 000',
        invoice: '',
        stage: 'notReady'
    },
    {
        status: 'completed',
        project: 'Lecture Communicating is creating connection',
        client: 'Galp',
        rule: 'Accepted - 70%',
        total: '20 000',
        invoice: '14 000',
        stage: 'sent'
    },
    {
        status: 'completed',
        project: 'Lecture Communicating is creating connection',
        client: 'Galp',
        rule: 'Completed - 30%',
        total: '20 000',
        invoice: '6 000',
        stage: 'sent'
    },
]
import {formatDate} from '@fullcalendar/core'
import styled from '@emotion/styled'
import Button from '@mui/material/Button'
import Popover from '@mui/material/Popover'

const Text = styled.div`
    font-size: 14px;
    line-height: 1.5;
    margin: 20px 0;
`

interface IEventPopup {
    id: string,
    open: boolean,
    anchorEl: HTMLElement | null,
    onClose: () => void,
    start: Date | null | undefined,
    title: string
}

const Content = styled.div`
    max-width: 300px;
    margin: 20px;
`

const Title = styled.span`
    font-size: 13px;
    color: #a0b7be;
    letter-spacing: 0.5px;
`

const EventPopup = (props: IEventPopup) => {
    const date = props.start ? formatDate(props.start, {
        month: 'long',
        year: 'numeric',
        day: 'numeric',
        weekday: 'long'
    }) : ''

    const time = props.start ? formatDate(props.start, {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    }) : ''

    return (
        <Popover
            open={props.open}
            anchorEl={props.anchorEl}
            onClose={props.onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
        >
            <Content>
                <Title>
                    {date} {time}
                </Title>
                    <Text>
                    Do you want to have a session with {props.title} on {date} at {time}?
                    </Text>
                    <Button
                        fullWidth
                        variant='contained'
                        color='primary'
                        onClick={props.onClose}
                    >
                        Reserve
                    </Button>
            </Content>
        </Popover>

    )
}

export default EventPopup
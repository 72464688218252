export default [
    {
        backgroundColor:  "#d1c4e9",
        borderColor: "#d1c4e9",
        end: "2023-06-03T14:00:00.000Z",
        id: "11788",
        start: "2023-06-03T13:45:00.000Z",
        textColor: "rgba(0, 0, 0, 0.87)",
        title: "Rogério Ferreira"
    },
    {
        backgroundColor:  "#b2ebf2",
        borderColor: "#b2ebf2",
        end: "2023-06-03T17:00:00.000Z",
        id: "11789",
        start: "2023-06-03T16:45:00.000Z",
        textColor: "rgba(0, 0, 0, 0.87)",
        title: "Maria Simoes"
    },
    {
        backgroundColor:  "#b2dfdb",
        borderColor: "#b2dfdb",
        end: "2023-06-10T11:00:00.000Z",
        id: "11790",
        start: "2023-06-10T10:00:00.000Z",
        textColor: "rgba(0, 0, 0, 0.87)",
        title: "Augusto Ferreira"
    },
    {
        backgroundColor:  "#b2dfdb",
        borderColor: "#b2dfdb",
        end: "2023-06-20T11:00:00.000Z",
        id: "11791",
        start: "2023-06-20T10:00:00.000Z",
        textColor: "rgba(0, 0, 0, 0.87)",
        title: "Augusto Ferreira"
    }
]
import React, {useEffect, useState} from 'react'
import {useNavigate, useParams, useLocation, matchPath} from 'react-router-dom'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import Chip from '@mui/material/Chip';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ChecklistIcon from '@mui/icons-material/Checklist';
import Schedule from '@mui/icons-material/Schedule';
import School from '@mui/icons-material/School';
import KeyboardVoice from '@mui/icons-material/KeyboardVoice';
import styled from '@emotion/styled';
import ListMenu from 'components/Menu'
import AcademiaTimetableItem from 'components/AcademiaTimetableItem'
import Breadcrumbs from 'components/Breadcrumbs'
import academiaTimetable from 'enums/academiaTimetable'

const Table = styled.div`
    overflow: auto;
`

const Header = styled.div`
    display: flex;
    top: 0px;
    position: sticky;
    z-index: 3;
    background-color: #f0f2f2;
    letter-spacing: 0.5px;
    color: #708085;
    font-size: 13px;
    border-bottom: 1px solid #d4d8da;
    background-color: #f0f2f2;
    min-width: 1400px;
    width: 100%;
`

const Cell = styled.div<{width?: number}>`
    box-sizing: border-box;
    width: ${props => props.width ? props.width : 200}px;
    padding: 20px;
    font-size: 14px;
    
    :not(:last-child) {
        border-right: 1px solid #d4d8da;
    }
`

const trainers = [
    {
        id: 1,
        name: 'Eduardo Cabrita'
    },
    {
        id: 2,
        name: 'Maria Simoes'
    },
    {
        id: 3,
        name: 'Maria Oliveira'
    }
]

const AcademiaSessions = () => {
    const [filter, setFilter] = useState<string>('')
    const [openFilter, setOpenFilter] = useState<HTMLDivElement>()

    const navigate = useNavigate()
    const { pathname } = useLocation()

    const onChipClick = (e: React.MouseEvent<HTMLDivElement>) => {
        setOpenFilter(e.currentTarget)
    }

    const handleClose = () => {
        setOpenFilter(undefined)
    }

    const onMenuItemClick = (value: string) => {
        if (value === filter) {
            setFilter('')
        } else {
            setFilter(value)
        }
        
        handleClose()
    }

    return (
        <React.Fragment>
            <Drawer
                sx={{
                    width: 250
                }}
                PaperProps={{
                    sx: {
                        width: 250
                    }
                }}
                variant="permanent"
                anchor="left"
            >
            <Toolbar />
            <Divider />
            <List>
                {['Tasks', 'History', 'Areas', 'Schedule', 'Trainees', 'Sessions'].map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton disableRipple href={`/academia/${text.toLocaleLowerCase()}`} selected={!!matchPath(`/academia/${text.toLocaleLowerCase()}`, pathname)}>
                            <ListItemIcon>
                                {index % 2 === 0 ? <ChecklistIcon /> : <Schedule />}
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <ListMenu />
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: '64px', marginLeft: '250px'}}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Breadcrumbs academia></Breadcrumbs>
                   <div style={{display: 'flex'}}>
                        <Chip icon={<KeyboardVoice sx={{fontSize: 18}}/>}
                        label={filter ? filter : 'Trainer'}
                        color={filter ? 'primary' : 'default'}
                        sx={{margin: '0 0 20px auto', cursor: 'pointer', width: '150px'}} onClick={onChipClick} />
                        <Chip icon={<School sx={{fontSize: 18}}/>} label="Trainee" sx={{width: '150px', margin: '0 0 20px 20px', cursor: 'pointer'}} />
                </div>
                </div>
                <Paper>
                    <Table>
                        <Header>
                            <Cell>Trainer</Cell>
                            <Cell>Trainee</Cell>
                            <Cell>Area</Cell>
                            <Cell>Date</Cell>
                            <Cell width={300}>Status</Cell>
                            <Cell width={300}>Report</Cell>
                        </Header>
                        {academiaTimetable.map((item, index) => (
                            <AcademiaTimetableItem key={index} area={item.area} date={item.date} trainer={item.trainer}
                            trainee={item.trainee} status={item.status} report={item.report}></AcademiaTimetableItem>
                        ))}
                    </Table>
                </Paper>
            </Box>
            <Menu
                anchorEl={openFilter}
                open={!!openFilter}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{sx: {
                    marginTop: '10px'
                }}}
            >
                {trainers.map(item => (
                    <MenuItem onClick={() => onMenuItemClick(item.name)}
                        key={item.name}
                        value={item.name}
                    >
                        {item.name}
                    </MenuItem>
                ))}
            </Menu>
        </React.Fragment>
    )
}

export default AcademiaSessions

export default [
    {
        member: 'Maria Oliveira',
        project: 'Communication Techniques and Public Speaking',
        client: 'Argo Partners',
        comission: '5',
        value: '1 000'
    },
    {
        member: 'João Pereira',
        project: 'Communication Techniques and Public Speaking',
        client: 'Argo Partners',
        comission: '5',
        value: '1 000'
    },
    {
        member: 'Maria Oliveira',
        project: 'Lecture Communicating is creating connection',
        client: 'Galp',
        comission: '10',
        value: '2 000'
    },
    {
        member: 'João Pereira',
        project: 'Lecture Communicating is creating connection',
        client: 'Galp',
        comission: '10',
        value: '1 000'
    },
    {
        member: 'Maria Oliveira',
        project: 'Lecture Communicating is creating connection',
        client: 'Santander',
        comission: '10',
        value: '1 000'
    },
    {
        member: 'João Pereira',
        project: 'Lecture Communicating is creating connection',
        client: 'Santander',
        comission: '10',
        value: '2 000'
    },
    {
        member: 'Maria Oliveira',
        project: 'Lecture Communicating is creating connection',
        client: 'Galp',
        comission: '10',
        value: '2 000'
    },
    {
        member: 'João Pereira',
        project: 'Lecture Communicating is creating connection',
        client: 'Galp',
        comission: '10',
        value: '1 000'
    },
]
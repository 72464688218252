export default [
    {
        id: 1,
        name: '7ª Comunique',
        start: '24.07.2022',
        end: '24.08.2022',
        status: 'complete'
    },
    {
        id: 2,
        name: '8ª Comunique',
        start: '24.07.2023',
        end: '24.08.2023',
        status: 'planning'
    },
    {
        id: 3,
        name: '9ª Comunique',
        start: '24.09.2022',
        end: '24.10.2022',
        status: 'delayed'
    },
    {
        id: 4,
        name: '10ª Comunique',
        start: '24.07.2023',
        end: '24.08.2023',
        status: 'planning'
    },
    {
        id: 5,
        name: '11ª Comunique',
        start: '24.07.2023',
        end: '24.08.2023',
        status: 'planning'
    },
    {
        id: 6,
        name: '12ª Comunique',
        start: '24.07.2023',
        end: '24.08.2023',
        status: 'planning'
    },
    {
        id: 7,
        name: '13ª Comunique',
        start: '24.07.2023',
        end: '24.08.2023',
        status: 'planning'
    },
    {
        id: 8,
        name: '14ª Comunique',
        start: '24.07.2023',
        end: '24.08.2023',
        status: 'planning'
    },
]
import React, {useEffect, useState} from 'react'
import {useNavigate, matchPath, useLocation, useOutletContext} from 'react-router-dom'
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ChecklistIcon from '@mui/icons-material/Checklist';
import Add from '@mui/icons-material/Add';
import TaskAlt from '@mui/icons-material/TaskAlt';
import AutoFixHigh from '@mui/icons-material/AutoFixHigh';
import PersonIcon from '@mui/icons-material/Person';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MailIcon from '@mui/icons-material/Mail';
import Chip from '@mui/material/Chip';
import GroupIcon from '@mui/icons-material/Group';
import Fab from '@mui/material/Fab';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import AttachFile from '@mui/icons-material/AttachFile'
import Schedule from '@mui/icons-material/Schedule';
import BarChart from '@mui/icons-material/BarChart';
import EditIcon from '@mui/icons-material/Edit';
import Insights from '@mui/icons-material/Insights';
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Subject from '@mui/icons-material/Subject';
import PercentIcon from '@mui/icons-material/Percent';
import Select, {SelectChangeEvent} from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import styled from '@emotion/styled'
import ListMenu from 'components/Menu'
import Breadcrumbs from 'components/Breadcrumbs'
import contacts from 'enums/contacts'
import statuses from 'enums/statuses'
import proposal from 'assets/proposal.pdf'

type colors = 'success' | 'error' | 'secondary'

const Proposal = styled.a`
    border-bottom: 1px solid #708085;
    padding-bottom: 2.5px;
    cursor: pointer;
    letter-spacing: 0.5px;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.87);
`

const Title = styled.div`
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    margin-bottom: 10px;
    color: #708085;
    font-size: 13px;
`

const Item = styled.div`
    font-size: 13px;
    margin-right: 50px;
    margin-bottom: 10px;
    min-width: 100px;
`

const Info = styled.div`
    display: flex;
    align-items: flex-start;
`

const Top = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.5px;
    position: relative;
`

const Table = styled.div`
    overflow: auto;
`

const Header = styled.div`
    display: flex;
    top: 0px;
    position: sticky;
    z-index: 3;
    background-color: #f0f2f2;
    letter-spacing: 0.5px;
    color: #708085;
    font-size: 13px;
    border-bottom: 1px solid #d4d8da;
    background-color: #f0f2f2;
    min-width: 1400px;
    width: 100%;
`

const Row = styled.div`
    display: flex;
    border-bottom: 1px solid #d4d8da;
    min-width: 1400px;
    width: 100%;
    align-items: stretch;
`

const Cell = styled.div<{width?: number}>`
    box-sizing: border-box;
    width: ${props => props.width ? props.width : 200}px;
    padding: 20px;
    font-size: 14px;
    
    :not(:last-child) {
        border-right: 1px solid #d4d8da;
    }
`

const Fixed = styled.div`
    width: 300px;
    left: 0px;
    position: sticky;
    z-index: 2;
    background-color: #f0f2f2;
    padding: 20px;
    border-right: 1px solid #d4d8da;
    font-size: 14px;
`

const FixedCell = styled.div`
    width: 300px;
    left: 0px;
    position: sticky;
    z-index: 2;
    background-color: #fcfcfc;
    padding: 20px;
    border-right: 1px solid #d4d8da;
    font-size: 14px;
`

const Status = styled.div`
    line-height: 20px;
    margin-right: 20px;
    border-bottom: 1px solid;
    font-weight: 500;
`

const chip = {
    marginBottom: '10px',
    marginRight: '10px'
}

const button = {
    position: 'absolute',
    right: '-30px',
    top: '-30px'
}

const paper = {
    padding: '20px',
    marginBottom: '20px',
    backgroundColor: '#f0f2f2'
}

const input = {
    marginBottom: '15px',
    marginTop: '15px'
}

const Project = () => {
    const [open, setOpen] = useState(false)
    const [status, setStatus] = useState<string>(statuses.complete.value)

    const navigate = useNavigate()
    const { pathname } = useLocation()

    const onButtonClick = () => {
        setOpen(true)
    }

    const onClose = () => {
        setOpen(false)
    }

    return (
        <React.Fragment>
            <Drawer
                sx={{
                    width: 250
                }}
                PaperProps={{
                    sx: {
                        width: 250
                    }
                }}
                variant="permanent"
                anchor="left"
            >
            <Toolbar />
            <Divider />
            <List>
                {['Tasks', 'History', 'Sessions', 'Schedule', 'Trainees', 'Proposal'].map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton disableRipple href={`/${text.toLocaleLowerCase()}`} selected={!!matchPath(text.toLocaleLowerCase(), pathname)}>
                            <ListItemIcon>
                                {index % 2 === 0 ? <ChecklistIcon /> : <Schedule />}
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <ListMenu />
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: '64px', marginLeft: '250px', minWidth: '900px'}}>
                <Breadcrumbs />
                <Paper sx={paper}>
                    <Top style={{marginBottom: '20px'}}>
                    Communication Techniques and Public Speaking
                        <Fab color='secondary' size='small' sx={button} onClick={onButtonClick}>
                            <EditIcon />
                        </Fab>
                    </Top>
                    <Info style={{display: 'flex', flexWrap: 'wrap'}}>
                        <Item>
                            <Title>
                                <PersonIcon sx={{marginRight: '5px', fontSize: 18}} /> Client
                            </Title>
                            <div>FrameLink</div>
                        </Item>
                        <Item>
                            <Title>
                                <AutoFixHigh sx={{marginRight: '5px', fontSize: 18}} /> Type
                            </Title>
                            <div>
                                <Chip label="Lecture" sx={{background: '#bbdefb'}} />
                            </div>
                        </Item>
                        <Item>
                            <Title>
                                <CalendarMonthIcon sx={{marginRight: '5px', fontSize: 18}} /> Start date
                            </Title>
                            <div>23.04.2023</div>
                        </Item>
                        <Item>
                            <Title>
                                <CalendarMonthIcon sx={{marginRight: '5px', fontSize: 18}} /> End date
                            </Title>
                            <div>23.05.2023</div>
                        </Item>
                        <Item>
                            <Title>
                                <GroupIcon sx={{marginRight: '5px', fontSize: 18}} /> Number of participants
                            </Title>
                            <div>35</div>
                        </Item>
                        <Item>
                            <Title>
                                <MailIcon sx={{marginRight: '5px', fontSize: 18}} /> Contact
                            </Title>
                            <div><Chip label="Maria Oliveira" /></div>
                        </Item>
                        <Item>
                            <Title>
                                <GroupIcon sx={{marginRight: '5px', fontSize: 18}} /> Team members
                            </Title>
                            <div><Chip label="Maria Oliveira" sx={{...chip, background: '#b2ebf2'}} />
                            <Chip label="João Pereira" sx={{...chip, background: '#d1c4e9'}} />
                            </div>
                        </Item>
                        <Item>
                            <Title>
                                <TaskAlt sx={{marginRight: '5px', fontSize: 18}} /> Status
                            </Title>
                            <div>
                                <Chip label="Complete" color="success" />
                            </div>
                        </Item>
                        <Item>
                            <Title>
                                <AttachFile sx={{marginRight: '5px', fontSize: 18}} /> Proposal
                            </Title>
                            <Proposal href={proposal} target="_blank">
                                Proposal.pdf
                            </Proposal>
                        </Item>
                        <Item>
                        <Title>
                            <PercentIcon sx={{marginRight: '5px', fontSize: 18}} /> Invoice Rules
                        </Title>
                        <div style={{display: 'flex'}}>
                        <Status style={{color: '#c30084'}}>Accepted - 30</Status>
                        <Status style={{color: '#ff8f00'}}>Setup done - 30</Status>
                        <Status style={{color: '#2e7d32'}}>Delivered - 40</Status>
                        </div>
                    </Item>
                    </Info>
                    <div style={{marginTop: '20px', fontSize: '14px', letterSpacing: '0.2px', lineHeight: '20px'}}>
                        <Title>
                            <Subject sx={{marginRight: '5px', fontSize: 18}} /> Context
                        </Title>
                    Randstad intends to make this training available to a new group of 35 people, with different challenges from the previous groups (shorter interviews - 30 minutes) for outsourcing (call centers and custom care). They already had theoretical training, now they want to move on to practice.
                    </div>
                   
                </Paper>
                <Paper>
                    <Table>
                        <Header>
                            <Fixed>Task</Fixed>
                            <Cell width={150}>Status</Cell>
                            <Cell width={350}>Subtask</Cell>
                            <Cell>Assigned to</Cell>
                            <Cell>Date</Cell>
                            <Cell>Comments</Cell>
                        </Header>
                        <Row>
                            <FixedCell>1. Find an event location</FixedCell>
                            <Cell width={150}><Chip label="In progress" sx={{background: '#b2dfdb'}} /></Cell>
                            <Cell width={350}>
                                <FormControlLabel control={<Checkbox defaultChecked size="small" />}
                                    label={<Typography sx={{ fontSize: 14 }}>Create shortlist of 3-5 locations</Typography>} />
                                <FormControlLabel control={<Checkbox size="small" />}
                                    label={<Typography sx={{ fontSize: 14 }}>Reach out and get available time and price</Typography>} />
                                <FormControlLabel control={<Checkbox size="small" />}
                                    label={<Typography sx={{ fontSize: 14 }}>Choose top 2 locations</Typography>} />
                            </Cell>
                            <Cell><Chip label="Maria Oliveira" /></Cell>
                            <Cell>24.02.2022</Cell>
                            <Cell></Cell>
                        </Row>
                        <Row>
                            <FixedCell>2. Prepare stuff for the event</FixedCell>
                            <Cell width={150}><Chip label="Delayed" sx={{background: '#fff9c4'}} /></Cell>
                            <Cell width={350}>
                                <FormControlLabel control={<Checkbox size="small" />}
                                    label={<Typography sx={{ fontSize: 14 }}>Coordinate with the client a list of necessary things</Typography>} />
                                <FormControlLabel control={<Checkbox size="small" />}
                                    label={<Typography sx={{ fontSize: 14 }}>Design and develop an identity</Typography>} />
                                <FormControlLabel control={<Checkbox size="small" />}
                                    label={<Typography sx={{ fontSize: 14 }}>Find a development company</Typography>} />    
                                <FormControlLabel control={<Checkbox size="small" />}
                                    label={<Typography sx={{ fontSize: 14 }}>Get feedback from client</Typography>} />
                            </Cell>
                            <Cell><Chip label="Maria Oliveira" /></Cell>
                            <Cell>24.02.2022</Cell>
                            <Cell></Cell>
                        </Row>
                        <Row>
                            <FixedCell>3. Make a list of lecturers</FixedCell>
                            <Cell width={150}><Chip label="In progress" sx={{background: '#b2dfdb'}} /></Cell>
                            <Cell width={350}>
                                <FormControlLabel control={<Checkbox size="small" />}
                                    label={<Typography sx={{ fontSize: 14 }}>Coordinate the schedule of lecturers</Typography>} />
                            </Cell>
                            <Cell><Chip label="Maria Oliveira" /></Cell>
                            <Cell>24.02.2022</Cell>
                            <Cell></Cell>
                        </Row>
                        <Row>
                            <FixedCell>4. Organize a catering service</FixedCell>
                            <Cell width={150}><Chip label="Rejected" sx={{background: '#ffcdd2'}} /></Cell>
                            <Cell width={350}>
                            </Cell>
                            <Cell><Chip label="Maria Oliveira" /></Cell>
                            <Cell>24.02.2022</Cell>
                            <Cell></Cell>
                        </Row>
                        <Row>
                            <FixedCell>5. Send 35 books</FixedCell>
                            <Cell width={150}><Chip label="Delayed" sx={{background: '#fff9c4'}} /></Cell>
                            <Cell width={350}>
                            </Cell>
                            <Cell><Chip label="Maria Oliveira" /></Cell>
                            <Cell>24.02.2022</Cell>
                            <Cell></Cell>
                        </Row>
                    </Table>
                </Paper>
            </Box>
            <Dialog open={open} scroll='body' onClose={onClose}>
                <DialogTitle>
                    Update project
                </DialogTitle>
                <DialogContent>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label='Project name'
                        defaultValue='Communication Techniques and Public Speaking'
                        sx={input}
                    />
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label='Client'
                        defaultValue='FrameLink'
                        sx={input}
                    />
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label='Date'
                        sx={input}
                        defaultValue='23.04.2023'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <CalendarTodayIcon />
                                </InputAdornment>
                            ),
                          }}
                    />
                     <div style={{marginBottom: '30px'}}>
                    <Autocomplete
                        sx={input}
                        multiple
                        options={contacts}
                        getOptionLabel={(option) => option.title}
                        defaultValue={[contacts[7]]}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                InputLabelProps={{ shrink: true }}
                                label="Contact"
                            />
                        )}
                    />
                     </div>
                    <div>
                    <Autocomplete
                        sx={input}
                        multiple
                        options={contacts}
                        getOptionLabel={(option) => option.title}
                        defaultValue={[contacts[7], contacts[0]]}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                InputLabelProps={{ shrink: true }}
                                label="Team members"
                            />
                        )}
                    />
                    </div>
                    <FormControl fullWidth sx={input}>
                        <InputLabel shrink={true}>Status</InputLabel>
                        <Select
                            notched
                            value={status}
                            label='Status'
                            inputProps={{
                                sx: {
                                    padding: '12px 14px'
                                }
                            }}
                            onChange={(e: SelectChangeEvent<string>) => setStatus(e.target.value as string)}
                            renderValue={(selected: string) => {
                                const color = Object.values(statuses).find(status => status.value === selected)!.color as colors
                                return <Chip key={selected} label={selected} color={color} />
                               
                            }}
                        >
                            {Object.values(statuses).map(item => (
                                <MenuItem
                                    key={item.value}
                                    value={item.value}
                                >
                                    {item.value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>
                        Cancel
                    </Button>
                    <Button onClick={onClose} variant='contained'>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default Project

import React, {useEffect, useState} from 'react'
import {useNavigate, useParams, useLocation, useOutletContext} from 'react-router-dom'
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BarChart from '@mui/icons-material/BarChart';
import Insights from '@mui/icons-material/Insights';
import Subject from '@mui/icons-material/Subject';
import DashboardCustomize from '@mui/icons-material/DashboardCustomize';
import PersonIcon from '@mui/icons-material/Person';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MailIcon from '@mui/icons-material/Mail';
import Chip from '@mui/material/Chip';
import styled from '@emotion/styled';
import contacts from 'enums/contacts'

type Types = {
    [key: string]: {
        name: string,
        color: string
    }
}

type Locales = {
    [key: string]: {
        name: string,
        color: string
    }
}

type Statuses = {
    [key: string]: {
        name: string,
        color: string
    }
}

const types: Types = {
    story: {
        name: "Storytelling",
        color: '#c5cae9'
    },
    image: {
        name: "Image and Success",
        color: '#bbdefb'
    },
    media: {
        name: "Media Training",
        color: '#b2dfdb'
    }
}

const locales: Locales = {
    presential: {
        name: "Presential",
        color: '#f3e5f5'
    },
    remote: {
        name: "Remote",
        color: '#e0f7fa'
    }
}

const statuses: Statuses = {
    invited: {
        name: "Invited",
        color: '#fff9c4'
    },
    accepted: {
        name: "Accepted",
        color: '#c8e6c9'
    },
    refused: {
        name: "Refused",
        color: '#ffcdd2'
    }
}

const Container = styled.div`
    display: flex;
    border-bottom: 1px solid #d4d8da;
    min-width: 1200px;
    width: 100%;
    align-items: stretch;
`

const Cell = styled.div<{width?: number}>`
    box-sizing: border-box;
    width: ${props => props.width ? props.width : 200}px;
    padding: 10px 20px;
    font-size: 14px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    
    :not(:last-child) {
        border-right: 1px solid #d4d8da;
    }
`

const chip = {
    marginBottom: '10px',
    marginRight: '10px'
}

const input = {
    marginBottom: '10px',
    marginTop: '10px'
}

interface IComponent {
    name: string,
    date: string,
    type: string,
    locale: string,
    address: string,
    status: string
}

const Row = (props: IComponent) => {
    return (
        <Container>
            <Cell><Chip label={types[props.type].name} sx={{background: types[props.type].color}} /></Cell>
            <Cell><span>{props.date}</span><CalendarMonthIcon sx={{marginLeft: '10px', fontSize: 20, color: '#708085'}} /></Cell>
            <Cell>{props.name}</Cell>
            <Cell><Chip label={locales[props.locale].name} sx={{background: locales[props.locale].color}} /></Cell>
            <Cell width={400}>{props.address}</Cell>
        </Container>
    )
}

export default Row

import React, {useState, FormEvent, ChangeEvent} from 'react'
import {useNavigate} from 'react-router-dom'
import {styled} from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import pattern from 'assets/pattern.png'

const Container = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${pattern})`,
    backgroundBlendMode: 'multiply',
    backgroundRepeat: 'repeat',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh'

}))

const Form = styled(Paper)(({theme}) => ({
    padding: '40px',
    width: '90%',
    maxWidth: '400px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}))

const style = {
    marginBottom: '20px'
}

const Login = () => {
    const [login, setLogin] = useState<string>('')

    const navigate = useNavigate()

    const onSubmitForm = (e: FormEvent) => {
        if (login === 'trainer') {
            navigate('/trainer/academias')
        } else if (login === 'trainee') {
            navigate('/trainee')
        } else if (login === 'project trainee') {
            navigate('/trainee/project')
        } else if (login === 'manager') {
            navigate('/manager/commissions')
        } else {
            navigate('/projects')
        }
    }

    const onChange = (e: ChangeEvent<HTMLInputElement>) => setLogin(e.target.value)

    return (
        <React.Fragment>
            <Container>
                <Form elevation={3}>
                    <form onSubmit={onSubmitForm} method='post'>
                        <TextField
                            InputLabelProps={{
                                shrink: true
                            }}
                            fullWidth
                            name='login'
                            label="Login"
                            onChange={onChange}
                            sx={{marginBottom: '20px'}}
                        />
                        <TextField
                            InputLabelProps={{
                                shrink: true
                            }}
                            fullWidth
                            name='password'
                            label="Password"
                            type='password'
                            sx={style}
                        />
                        <Button
                            fullWidth
                            variant='contained'
                            type='submit'
                        >
                            Sign in
                        </Button>
                    </form>
                </Form>
            </Container>
        </React.Fragment>
    )
}

export default Login

import React, {useEffect, useState} from 'react'
import {useNavigate, useParams, useLocation, matchPath} from 'react-router-dom'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Chip from '@mui/material/Chip';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import Fab from '@mui/material/Fab';
import Skeleton from '@mui/material/Skeleton';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Addchart from '@mui/icons-material/Addchart';
import AttachFile from '@mui/icons-material/AttachFile';
import TaskAlt from '@mui/icons-material/TaskAlt';
import Schedule from '@mui/icons-material/Schedule';
import Grade from '@mui/icons-material/Grade';
import Grading from '@mui/icons-material/Grading'
import EditCalendar from '@mui/icons-material/EditCalendar'
import styled from '@emotion/styled';
import proposal from 'assets/proposal.pdf'

const Title = styled.div`
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    margin-bottom: 10px;
    color: #708085;
`

const Item = styled.div`
    font-size: 13px;
    margin-bottom: 10px;
    margin-right: 40px;
`

const Assets = styled.div`
    font-size: 13px;
    padding: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    margin-right: 40px;
`

const Top = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.5px;
    position: relative;
`

const Info = styled.div`
    display: flex;
    align-items: flex-start;
`

const Link = styled.a`
    cursor: pointer;
    letter-spacing: 0.5px;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.87);
    border-bottom: 1px solid;
    padding-bottom: 2.5px;
`

const Area = styled.div`
    width: 150px;
    height: 170px;
    border: 1px solid rgba(0, 0, 0, 0.11);
    color: #708085;
    border-radius: 4px/6.7px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const main = {
    padding: '20px',
    marginBottom: '20px',
    backgroundColor: '#f0f2f2'
}

const secondary = {
    padding: '20px',
    marginBottom: '20px',
    backgroundColor: '#fcfcfc',
    border: '1px solid #d4d8da',
    boxShadow: 'none'
}

const calendar = {
    position: 'absolute',
    right: '25px',
    top: '-30px'
}

const rate = {
    position: 'absolute',
    right: '-30px',
    top: '-30px'
}

const Desc = styled.a`
    cursor: pointer;
    letter-spacing: 0.5px;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
`

const Border = styled.div`
    border: 5px solid rgba(0, 0, 0, 0.11);
    border-radius: 4px/6.7px;
    padding: 5px;
    flex: 0 0 auto;
    width: 150px;
    height: 170px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const Trainee = () => {
    const { pathname } = useLocation()
    const navigate = useNavigate()

    const onClick = () => {
        navigate('/trainee/schedule')
    }

    return (
        <React.Fragment>
            <Drawer
                sx={{
                    width: 250
                }}
                PaperProps={{
                    sx: {
                        width: 250
                    }
                }}
                variant="permanent"
                anchor="left"
            >
            <Toolbar />
            <Divider />
                <List>
                    {['Trainee'].map((text, index) => (
                        <ListItem key={text} disablePadding>
                            <ListItemButton href={`/${text.toLocaleLowerCase()}`} selected={!!matchPath(text.toLocaleLowerCase(), pathname)}>
                                <ListItemIcon>
                                    {index % 2 === 0 ? <Addchart /> : <Schedule />}
                                </ListItemIcon>
                                <ListItemText primary={text} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: '64px', marginLeft: '250px', minWidth: '900px'}}>
                <Paper sx={main}>
                    <Top style={{marginBottom: '20px'}}>
                        7ª Comunique
                        <Fab color='primary' size='small' sx={calendar} onClick={onClick}>
                            <EditCalendar />
                        </Fab>
                        <Fab color='secondary' size='small' sx={rate}>
                            <Grade />
                        </Fab>
                    </Top>
                    <Info style={{display: 'flex', flexWrap: 'wrap'}}>
                        <Item>
                            <Title>
                                <CalendarMonth sx={{marginRight: '5px', fontSize: 18}} /> Start date
                            </Title>
                            <div>24.07.2023</div>
                        </Item>
                        <Item>
                            <Title>
                                <CalendarMonth sx={{marginRight: '5px', fontSize: 18}} /> End date
                            </Title>
                            <div>24.08.2023</div>
                        </Item>
                        <Item>
                            <Title>
                                <CalendarMonth sx={{marginRight: '5px', fontSize: 18}} /> Kick off meeting
                            </Title>
                            <div>23.07.2023</div>
                        </Item>
                        <Item>
                            <Title>
                                <AttachFile sx={{marginRight: '5px', fontSize: 18}} /> Join link
                            </Title>
                            <Link href='https://zoom.com/meetings/1234/qwerty'>https://zoom.com/meetings/1234/qwerty</Link>
                        </Item>
                        <Item>
                            <Title>
                                <AttachFile sx={{marginRight: '5px', fontSize: 18}} /> Diploma
                            </Title>
                            <Link href='https://zoom.com/meetings/1234/qwerty'>Diploma.pdf</Link>
                        </Item>
                        <Item>
                            <Title>
                                <AttachFile sx={{marginRight: '5px', fontSize: 18}} /> Report
                            </Title>
                            <Link href='https://zoom.com/meetings/1234/qwerty'>Report.pdf</Link>
                        </Item>
                    </Info>
                </Paper>
                <Paper sx={secondary}>
                    <Info style={{display: 'flex'}}>
                        <Assets>
                            <Title>
                                <AttachFile sx={{marginRight: '5px', fontSize: 18}} /> Presentation video
                            </Title>
                            <Link>drive.google.com/drive/my-drive</Link>
                        </Assets>
                        <Assets>
                            <Title>
                                <TaskAlt sx={{marginRight: '5px', fontSize: 18}} /> Final video
                            </Title>
                            <Link>drive.google.com/drive/my-drive</Link>
                        </Assets>
                        <Assets>
                            <Title>
                                <Grading sx={{marginRight: '5px', fontSize: 18}} /> Confirmation documents
                            </Title>
                            <div>
                            <Desc href={proposal} target="_blank">
                                <Border>
                                    <Skeleton animation={false} />
                                    <Skeleton animation={false} />
                                    <Skeleton animation={false} />
                                    <Skeleton animation={false} />
                                    <Skeleton animation={false} />
                                    <Skeleton animation={false} />
                                    <Skeleton animation={false} />
                                    <Skeleton animation={false} />
                                    
                                    <Skeleton animation={false} width='80%' />
                                </Border>
                                
                                <Area>Click to upload</Area>
                            </Desc>
                            </div>
                        </Assets>
                    </Info>
                </Paper>
            </Box>
        </React.Fragment>
    )
}

export default Trainee

import React, {useRef, useState} from 'react'
import {useNavigate, useParams, useLocation, matchPath} from 'react-router-dom'
import FullCalendar from '@fullcalendar/react'
import {EventDropArg, EventClickArg, MoreLinkArg, EventSegment, EventApi} from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import NavigateBefore from '@mui/icons-material/NavigateBefore'
import Bookmark from '@mui/icons-material/Bookmark'
import styled from '@emotion/styled';
import ListMenu from 'components/Menu'
import EventPopup from 'components/EventPopup'
import Row from 'components/Row'
import schedule from 'enums/schedule'

const Container = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;

    .fc .fc-toolbar.fc-header-toolbar {
        padding: 10px 0;
        margin-bottom: 0;
        background: #f0f2f2;
        height: 50px;
        border-bottom: 1px solid #dddddd;
    }

    .fc .fc-toolbar-title {
        font-size: 20px;
        font-weight: 500;
        color: #4a4a4a;
        letter-spacing: 0.5px;
    }

    .fc .fc-col-header-cell-cushion {
        padding: 5px;
        letter-spacing: 0.5px;
        color: #708085;
        font-weight: 500;
        font-size: 12px;
    }

    .fc-daygrid-block-event .fc-event-time {
        font-weight: 500;
    }

    .fc-theme-standard .fc-scrollgrid {
        border: none;
    }

    .fc .fc-button {
        border: none;
        text-transform: uppercase;
        letter-spacing: 1px;
	    font-size: 12px;
        padding: 5px 15px;
        border-radius: 0;
    }

    .fc .fc-button-primary {
        background-color: transparent;
        color: #4a4a4a;
    }

    .fc .fc-button-primary:focus,
    .fc .fc-button-primary:not(:disabled).fc-button-active:focus,
    .fc .fc-button-primary:not(:disabled):active:focus {
        box-shadow: none;
    }

    .fc .fc-button-primary:not(:disabled).fc-button-active,
    .fc .fc-button-primary:not(:disabled):active {
        background-color: transparent;
        color: #4a4a4a;
    }

    .fc .fc-daygrid-more-link {
        width: 100%;
    }

    .fc .fc-daygrid-more-link:hover {
        background-color: transparent;
    }

    .fc .fc-daygrid-day-frame {
        min-height: 100px;
    }

    .fc-h-event .fc-event-title {
        text-overflow: ellipsis;
    }

    .fc-h-event .fc-event-time {
        overflow: visible;
    }

    .fc .fc-col-header-cell-cushion {
        padding: 5px;
    }

    fc .fc-daygrid-day-number {
        padding: 2px 4px;
    }

    .fc .fc-event {
        cursor: pointer;
    }

    .fc-theme-standard .fc-list {
        border: none;
    }

    .fc-event-selected:after, .fc-event:focus:after {
        background: none;
    }

    .fc-event-selected, .fc-event:focus {
        box-shadow: none;
    }
`

const Title = styled.div`
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.5px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    color: #4a4a4a;
    height: 49px;
`

const Item = styled.div`
    display: flex;
    flex-direction: column;
`

const list = {
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    fontSize: '13px',
    padding: '10px 20px'
}

type Types = {
    [key: string]: {
        name: string,
        color: string
    }
}

const types: Types = {
    story: {
        name: "Storytelling",
        color: '#c5cae9'
    },
    image: {
        name: "Image and Success",
        color: '#bbdefb'
    },
    media: {
        name: "Media Training",
        color: '#b2dfdb'
    }
}

const data = [
    {
        id: 1,
        area: 'media',
        trainer: 'Eduardo Cabrita',
        booked: true,
    },
    {
        id: 2,
        area: 'story',
        trainer: 'Maria Oliveira',
        booked: true,
    },
    {
        id: 3,
        area: 'image',
        trainer: 'Maria Simoes',
    }
]

const TraineeSchedule = () => {
    const [open, setOpen] = useState<HTMLElement | null>(null)
    const [filter, setFilter] = useState<string>('')
    const event = useRef<EventApi | undefined>()
    const navigate = useNavigate()

    const eventClick = (data: EventClickArg) => {
        setOpen(data.el)
        event.current = data.event
    }

    const onClose = () => {
        setOpen(null)
    }

    const onFilterClick = (trainer: string) => {
        if (filter === trainer) {
            setFilter('')
        } else {
            setFilter(trainer)
        }
    }

    const onButtonClick = () => {
        navigate('/trainee')
    }

    const events = filter ? schedule.filter(item => item.title === filter) : schedule

    return (
        <React.Fragment>
            <Drawer
                sx={{
                    width: 250
                }}
                PaperProps={{
                    sx: {
                        width: 250
                    }
                }}
                variant="permanent"
                anchor="left"
            >
                <Toolbar />
                <Title>
                    <IconButton onClick={onButtonClick} sx={{color:'#4a4a4a', marginRight: '10px', marginTop: '2px'}}><NavigateBefore /></IconButton> 7ª Comunique
                </Title>
                <Divider />
            <List>
                {data.map((item) => (
                    <ListItem key={item.id} disablePadding>
                        
                        <ListItemButton sx={list} disableRipple selected={item.trainer === filter} onClick={() => onFilterClick(item.trainer)}>
                        <Item>
                            <Chip label={types[item.area].name} sx={{background: types[item.area].color, marginBottom: '10px', borderRadius: '5px', height: '20px'}} />
                            {item.trainer}
                        </Item>
                       {item.booked &&  <Tooltip title="Session booked" placement="bottom">
                            <Bookmark sx={{color: '#4caf50'}} />
                        </Tooltip>}
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, marginTop: '64px', marginLeft: '250px', height: 'calc(100vh - 64px)'}}>
                <Container>
                    <FullCalendar
                        headerToolbar ={{
                            center: 'title',
                            end: '',
                            start: 'prev next today'
                        }}
                        views={{
                            dayGridMonth: {
                                dayMaxEventRows: 4
                            }
                        }}
                        initialView="dayGridMonth"
                        firstDay={1}
                        plugins={[ dayGridPlugin ]}
                        eventDisplay='block'
                        height='100%'
                        dayMaxEventRows={true}
                        expandRows={true}
                        events={events}
                        eventTimeFormat={{
                            hour: '2-digit',
                            minute: '2-digit',
                            meridiem: false,
                            hour12: false
                        }}
                        eventClick={eventClick}
                    />
                </Container>
            </Box>
            {event.current &&
                <EventPopup
                    open={!!open}
                    anchorEl={open}
                    onClose={onClose}
                    id={event.current.id}
                    start={event.current.start}
                    title={event.current.title}
                />
            }
        </React.Fragment>
    )
}

export default TraineeSchedule

export default [
    {
        academia: '20 Communique',
        client: 'Juan Carlos',
        rule: 'Enroll - 100%',
        enrollDate: '24.07.2023',
        dueDate: '24.07.2023',
        invoice: '1 000',
        stage: 'sent'
    },
    {
        academia: '20 Communique',
        client: 'Ana Almeida',
        rule: 'Enroll - 50%',
        enrollDate: '24.07.2023',
        dueDate: '24.07.2023',
        invoice: '500',
        stage: 'ready'
    },
    {
        academia: '20 Communique',
        client: 'Ana Almeida',
        rule: '30 days after - 50%',
        enrollDate: '24.07.2023',
        dueDate: '24.08.2023',
        invoice: '500',
        stage: 'notReady'
    },
    {
        academia: '19 Communique',
        client: 'Juan Carlos',
        rule: 'Enroll - 100%',
        enrollDate: '24.07.2023',
        dueDate: '24.07.2023',
        invoice: '1 000',
        stage: 'sent'
    },
    {
        academia: '19 Communique',
        client: 'Ana Almeida',
        rule: 'Enroll - 50%',
        enrollDate: '24.07.2023',
        dueDate: '24.07.2023',
        invoice: '500',
        stage: 'ready'
    },
    {
        academia: '19 Communique',
        client: 'Ana Almeida',
        rule: '30 days after - 50%',
        enrollDate: '24.07.2023',
        dueDate: '24.08.2023',
        invoice: '500',
        stage: 'notReady'
    },
    {
        academia: '18 Communique',
        client: 'Juan Carlos',
        rule: 'Enroll - 100%',
        enrollDate: '24.07.2023',
        dueDate: '24.07.2023',
        invoice: '1 000',
        stage: 'sent'
    },
    {
        academia: '18 Communique',
        client: 'Ana Almeida',
        rule: 'Enroll - 50%',
        enrollDate: '24.07.2023',
        dueDate: '24.07.2023',
        invoice: '500',
        stage: 'ready'
    },
    {
        academia: '18 Communique',
        client: 'Ana Almeida',
        rule: '30 days after - 50%',
        enrollDate: '24.07.2023',
        dueDate: '24.08.2023',
        invoice: '500',
        stage: 'notReady'
    },
]
import React, {useEffect, useState} from 'react'
import {useNavigate, useParams, useLocation, matchPath} from 'react-router-dom'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Tooltip from '@mui/material/Tooltip';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Add from '@mui/icons-material/Add';
import TaskAlt from '@mui/icons-material/TaskAlt';
import PendingActions from '@mui/icons-material/PendingActions';
import AttachFile from '@mui/icons-material/AttachFile';
import CreditCard from '@mui/icons-material/CreditCard';
import Chip from '@mui/material/Chip';
import GroupIcon from '@mui/icons-material/Group';
import ChecklistIcon from '@mui/icons-material/Checklist'
import Schedule from '@mui/icons-material/Schedule';
import BarChart from '@mui/icons-material/BarChart';
import KeyboardVoice from '@mui/icons-material/KeyboardVoice';
import Insights from '@mui/icons-material/Insights';
import Subject from '@mui/icons-material/Subject';
import Fab from '@mui/material/Fab';
import Skeleton from '@mui/material/Skeleton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import styled from '@emotion/styled';
import ListMenu from 'components/Menu'
import ProposalItem from 'components/ProposalItem'
import Breadcrumbs from 'components/Breadcrumbs'
import proposal from 'assets/proposal.pdf'
import trainers from 'enums/trainers'
import extras from 'enums/extras'
import statuses from 'enums/statuses'

const Accordion = styled.div`
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`

const Table = styled.div`
    overflow: auto;
`

const Header = styled.div`
    display: flex;
    top: 0px;
    position: sticky;
    z-index: 3;
    background-color: #f0f2f2;
    letter-spacing: 0.5px;
    color: #708085;
    font-size: 13px;
    border-bottom: 1px solid #d4d8da;
    background-color: #f0f2f2;
    min-width: 900px;
    width: 100%;
    height: 45px;
`

const Footer = styled.div`
    display: flex;
    bottom: 0px;
    position: sticky;
    z-index: 3;
    background-color: #f0f2f2;
    letter-spacing: 0.5px;
    color: #708085;
    font-size: 13px;
    border-bottom: 1px solid #d4d8da;
    background-color: #fcfcfc;
    min-width: 900px;
    width: 100%;
    height: 45px;
`

const Cell = styled.div<{width?: number}>`
    box-sizing: border-box;
    width: ${props => props.width ? props.width : 200}px;
    padding: 10px 20px;
    font-size: 14px;
    display: flex;
    align-items: center;
    
    :not(:last-child) {
        border-right: 1px solid #d4d8da;
    }
`

const CellFooter = styled.div<{width?: number}>`
    box-sizing: border-box;
    width: ${props => props.width ? props.width : 200}px;
    padding: 10px 20px;
    font-size: 14px;
    display: flex;
    align-items: center;
    
    :not(:last-child) {
        border-right: 1px solid #d4d8da;
    }

    color: #2e7d32;
    font-weight: 600;
`

const Count = styled.div`
    color: #2e7d32;
    font-weight: 600;
`

const Title = styled.div`
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    margin-bottom: 10px;
    color: #708085;
`

const Item = styled.div`
    font-size: 13px;
    margin-bottom: 10px;
    cursor: pointer;
    margin-right: 40px;
`

const Info = styled.div`
    display: flex;
    align-items: flex-start;
`

const Top = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.5px;
    position: relative;
`

const Link = styled.a`
    cursor: pointer;
    letter-spacing: 0.5px;
    width: 300px;
    height: 70px;
    text-decoration: none;
    display: flex;
    color: rgba(0, 0, 0, 0.87);
    line-height: 20px;
`

const Border = styled.div`
    border: 5px solid rgba(0, 0, 0, 0.11);
    border-radius: 4px/6.7px;
    padding: 5px;
    flex: 0 0 auto;
    width: 90px;
    margin-right: 10px;
`

const button = {
    position: 'absolute',
    right: '-10px',
    bottom: '-10px'
}

 const main = {
    padding: '20px',
    marginBottom: '20px',
    backgroundColor: '#f0f2f2'
}

const chip = {
    marginBottom: '10px',
    marginRight: '10px'
}

const Proposal = () => {
    const [open, setOpen] = useState(true)

    const { pathname } = useLocation()

    const onButtonClick = () => {
        setOpen((open) => !open)
    }

    const onClose = () => {
        setOpen(false)
    }

    return (
        <React.Fragment>
            <Drawer
                sx={{
                    width: 250
                }}
                PaperProps={{
                    sx: {
                        width: 250
                    }
                }}
                variant="permanent"
                anchor="left"
            >
                <Toolbar />
                <Divider />
                <List>
                    {['Tasks', 'History', 'Sessions', 'Schedule', 'Trainees', 'Proposal'].map((text, index) => (
                        <ListItem key={text} disablePadding>
                            <ListItemButton disableRipple href={`/${text.toLocaleLowerCase()}`} selected={!!matchPath(text.toLocaleLowerCase(), pathname)}>
                                <ListItemIcon>
                                    {index % 2 === 0 ? <ChecklistIcon /> : <Schedule />}
                                </ListItemIcon>
                                <ListItemText primary={text} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
                <ListMenu />
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: '64px', marginLeft: '250px', minWidth: '900px'}}>
                <Breadcrumbs />
                <Paper sx={main}>
                    <Top style={{marginBottom: '20px'}}>
                    Communication Techniques and Public Speaking
                    </Top>
                    <Info style={{display: 'flex', flexWrap: 'wrap'}}>
                    <Tooltip title="Click to copy" placement="bottom-start">
                        <Item>
                            <Title>
                                <PendingActions sx={{marginRight: '5px', fontSize: 18}} /> Trainers resources
                            </Title>
                            <Count>3 100 €</Count>
                        </Item>
                        </Tooltip>
                        <Tooltip title="Click to copy" placement="bottom-start">
                        <Item>
                            <Title>
                                <CreditCard sx={{marginRight: '5px', fontSize: 18}} /> Additional expenses
                            </Title>
                            <Count>5 000 €</Count>
                        </Item>
                        </Tooltip>
                        <Tooltip title="Click to copy" placement="bottom-start">
                        <Item>
                            <Title>
                                <KeyboardVoice sx={{marginRight: '5px', fontSize: 18}} /> Trainers
                            </Title>
                            <div><Chip label="Maria Simoes" sx={chip} />
                            <Chip label="Augusto Ferreira" sx={chip} />
                            <Chip label="Eduardo Cabrita" sx={chip} />
                            </div>
                        </Item>
                        </Tooltip>
                        <Item>
                            <Title>
                                <GroupIcon sx={{marginRight: '5px', fontSize: 18}} /> Team members
                            </Title>
                            <div><Chip label="Maria Oliveira" sx={{...chip, background: '#b2ebf2'}} />
                            <Chip label="João Pereira" sx={{...chip, background: '#d1c4e9'}} />
                            </div>
                        </Item>
                        <Item>
                            <Title>
                                <TaskAlt sx={{marginRight: '5px', fontSize: 18}} /> Areas
                            </Title>
                            <div>
                                <Chip label="Storytelling" sx={{...chip, background: '#c5cae9'}} />
                                <Chip label="Image and Success" sx={{...chip, background: '#bbdefb'}} />
                                <Chip label="Media Training" sx={{...chip, background: '#b2dfdb'}} />
                            </div>
                        </Item>
                        <Item>
                            <Title>
                                <AttachFile sx={{marginRight: '5px', fontSize: 18}} /> Attachments
                            </Title>
                            <div>
                            <Link href={proposal} target="_blank">
                                <Border>
                                    <Skeleton animation={false} height={10} />
                                    <Skeleton animation={false} height={10} />
                                    <Skeleton animation={false} height={10} />
                                    <Skeleton animation={false} height={10} />
                                    <Skeleton animation={false} height={10} width='80%' />
                                </Border>
                                <div>Proposta Técnicas Diferenciadoras de Entrevista Randstad.pdf</div>
                            </Link>
                            </div>
                        </Item>
                    </Info>
                    <Tooltip title="Click to copy" placement="bottom-start">
                    <div style={{marginTop: '20px', fontSize: '14px', letterSpacing: '0.2px', lineHeight: '20px', cursor: 'pointer'}}>
                        <Title>
                            <Subject sx={{marginRight: '5px', fontSize: 18}} /> Context
                        </Title>
                        Randstad intends to make this training available to a new group of 35 people, with different challenges from the previous groups (shorter interviews - 30 minutes) for outsourcing (call centers and custom care). They already had theoretical training, now they want to move on to practice.
                    </div>
                    </Tooltip>
                </Paper>

                {/*<Paper sx={paper}>
                    <Total>
                        <Group sx={{marginRight: '15px', fontSize: 18}} />
                        Evaluation of trainers resources
                    </Total>
                   
                        <CellFooter style={{marginRight: '30px'}}>
                            <Schedule sx={{marginRight: '10px', fontSize: 18}} />
                            23 hours
                        </CellFooter>
                        <CellFooter>
                            <Balance sx={{marginRight: '10px', fontSize: 18}} />
                            3 100 €
                        </CellFooter>
                   
                    </Paper> */}
                <Paper sx={{position: 'relative', marginBottom: '20px'}}>
                    <Table>
                        <Header>
                            <Cell width={300}>
                                {/*<IconButton size='small' sx={button} onClick={onButtonClick}>
                                    <KeyboardDoubleArrowDown sx={!open ? {...icon, transform: 'rotate(-90deg)'} : {...icon}} />
                    </IconButton>*/}
                                Trainer</Cell>
                            <Cell>Hourly price</Cell>
                            <Cell>Hours</Cell>
                            <Cell>Total</Cell>
                        </Header>
                        <Accordion style={open ? {height: trainers.length * 45 + 'px'} : {height: 0}}>
                            {trainers.map((item, index) => (
                                <ProposalItem key={index} name={item.title} price={item.price} units={item.hours} total={item.total}></ProposalItem>
                            ))}
                        </Accordion>
                        <Footer>
                            <CellFooter width={300}> </CellFooter>
                            <CellFooter></CellFooter>
                            <CellFooter>23 hours</CellFooter>
                            <CellFooter>3 100 €</CellFooter>
                            </Footer>
                    </Table>
                </Paper>
                
                {/* <Paper sx={{...paper, marginTop: '40px'}}>
                    <Total>
                        <ShoppingCartOutlinedIcon sx={{marginRight: '15px', fontSize: 18}} />
                        Evaluation of additional costs
                    </Total>
                   
                       
                        <CellFooter>
                            <Balance sx={{marginRight: '10px', fontSize: 18}} />
                            5 000 €
                        </CellFooter>
                   
                            </Paper> */}
                <Paper sx={{position: 'relative'}}>
                    <Table>
                        <Header>
                            <Cell width={300}>
                                {/*<IconButton size='small' sx={button} onClick={onButtonClick}>
                                    <KeyboardDoubleArrowDown sx={!open ? {...icon, transform: 'rotate(-90deg)'} : {...icon}} />
                        </IconButton>*/}
                                Additional expense</Cell>
                            <Cell>Price</Cell>
                            <Cell>Units</Cell>
                            <Cell>Total</Cell>
                        </Header>
                        <Accordion style={open ? {height: extras.length * 45 + 'px'} : {height: 0}}>
                            {extras.map((item, index) => (
                                <ProposalItem key={index} name={item.title} price={item.price} units={item.units} total={item.total}></ProposalItem>
                            ))}
                        </Accordion>
                        <Footer>
                            <CellFooter width={300}> </CellFooter>
                            <CellFooter></CellFooter>
                            <CellFooter></CellFooter>
                            <CellFooter>5 000 €</CellFooter>
                            </Footer>
                    </Table>
                    <Fab color='secondary' size='small' sx={button}>
                            <Add />
                        </Fab>
                </Paper>
                
            </Box>
        </React.Fragment>
    )
}

export default Proposal

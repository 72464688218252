import React, {useEffect, useState} from 'react'
import {matchPath, useLocation} from 'react-router-dom'
import Link from '@mui/material/Link'
import BreadcrumbsComponent from '@mui/material/Breadcrumbs'
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const links = {
    marginBottom: '20px',
    letterSpacing: '0.5px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '14px'
}

const typography = {
    letterSpacing: '0.5px',
    color: '#708085',
    fontSize: '14px'
}

interface IComponent {
    academia?: boolean
}

const breadcrumbs = [
    <Link underline='none' key="1" color="inherit" href="/projects">
        Projects
    </Link>,
    <Typography key="2" color="text.primary" sx={typography}>
        Communication Techniques and Public Speaking
    </Typography>,
]

const academia = [
    <Link underline='none' key="1" color="inherit" href="/academias">
        Academias
    </Link>,
    <Typography key="2" color="text.primary" sx={typography}>
       7ª Comunique
    </Typography>,
]

const Breadcrumbs = (props: IComponent) => {
    const { pathname } = useLocation()

    return (
        <BreadcrumbsComponent separator={<NavigateNextIcon fontSize="small" />} sx={links}>
            {props.academia ? academia : breadcrumbs}
        </BreadcrumbsComponent>
    )
}

export default Breadcrumbs
